import React, { useEffect, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { environment } from '~/environments';
import { UserInfo } from './UserInfo';

export const ConfirmBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [searchParams] = useSearchParams();

  const confirmBooking = async () => {
    setLoading(true);
    setError(false);
    try {
      await axios.get(
        `${environment.apiUrl}/allocations/confirm/${searchParams.get(
          'f',
        )}/${searchParams.get('p')}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.response?.data === '[Allocation] Invalid link') {
        setError('Booking not available.');
      } else if (e?.response?.data === '[Allocation] Link no longer active') {
        setError('Booking request has already expired.');
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    confirmBooking();
  }, [searchParams]);

  return (
    <UserInfo
      loading={loading}
      title={
        error
          ? error !== true
            ? error
            : 'Ooops, something went wrong...'
          : 'Your service with Volubus has been confirmed'
      }
    >
      {error && (
        <>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                width: { sm: 350, lg: 445 },
                fontFamily: 'Messina Sans',
                marginBottom: 20,
              }}
            >
              {error === 'Booking request has already expired.' &&
                'This booking inquiry is no longer valid or may have been already assigned. '}
              In case of any questions, please contact us at{' '}
              <Link
                href="mailto:operations@volubus.com"
                sx={{ color: '#ffffff' }}
              >
                operations@volubus.com
              </Link>{' '}
              or&nbsp;+46&nbsp;40&nbsp;668&nbsp;88&nbsp;87.
            </Typography>
          </Grid>
        </>
      )}

      {!error && (
        <>
          <Grid item sx={{ marginBottom: 3 }}>
            <Typography
              variant="body1"
              sx={{
                width: { sm: 350, lg: 445 },
                fontFamily: 'Messina Sans',
              }}
            >
              Confirmation email with our company details and further
              instructions has been sent to your email.
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              sx={{
                width: { sm: 350, lg: 445 },
                fontFamily: 'Messina Sans',
                marginBottom: 20,
              }}
            >
              In case of any questions, please contact us at{' '}
              <Link
                href="mailto:operations@volubus.com"
                sx={{ color: '#ffffff' }}
              >
                operations@volubus.com
              </Link>{' '}
              or&nbsp;+46&nbsp;40&nbsp;668&nbsp;88&nbsp;87.
            </Typography>
          </Grid>
        </>
      )}
    </UserInfo>
  );
};

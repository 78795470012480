import React from 'react';
import { Dialog } from '@mui/material';

import { Strategy, StrategyInput } from '~/types';
import { api } from '~/utils';

import { EditStrategyDialog } from './EditStrategyDialog';

type Props = {
  strategy: Strategy;
  onSuccess: () => void;
  onClose: () => void;
};

export const EditStrategy = ({ strategy, onSuccess, onClose }: Props) => {
  const handleSubmit = async (data: StrategyInput) => {
    try {
      await api.patch(`/strategies/${strategy.id}`, data);
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error editing strategy');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <EditStrategyDialog
        strategy={strategy}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

import React from 'react';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';

type Props = {
  isCard?: boolean;
  message?: string;
};

export const Loading = ({ isCard = false, message }: Props) => {
  const content = (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      {message && (
        <Grid item>
          <Typography variant="body1">{message}</Typography>
        </Grid>
      )}
    </Grid>
  );

  return isCard ? (
    <Card sx={{ width: '100%', height: '100%' }}>{content}</Card>
  ) : (
    <Box sx={{ width: '100%', height: '100%' }}>{content}</Box>
  );
};

import { Booking, TableColumn } from '~/types';

export const getBookingTableColumns = (): TableColumn<Booking>[] => {
  return [
    {
      name: 'id',
      label: 'ID',
      minWidth: 120,
      sortColumn: 'booking_id',
    },
    {
      name: 'status',
      label: 'Status',
      minWidth: 160,
      sortColumn: 'status',
    },
    {
      name: 'country',
      label: 'Country',
      sortColumn: 'pickup_country',
    },
    {
      name: 'date',
      label: 'Date/Time',
      sortColumn: 'date',
      minWidth: 250,
    },
    {
      name: 'pickupLocation',
      label: 'Pickup Location',
      minWidth: 150,
    },
    {
      name: 'destination',
      label: 'Destination',
      minWidth: 150,
    },
    {
      name: 'clientName',
      label: 'Client Name',
      sortColumn: 'client_name',
    },
    {
      name: 'clientPrice',
      label: 'Client Price',
      sortColumn: 'gross_client_price',
    },
    {
      name: 'supplierName',
      label: 'Supplier Name',
      sortColumn: 'supplier_name',
    },
    {
      name: 'grossSupplierPrice',
      label: 'Supplier Price',
      sortColumn: 'gross_supplier_price',
    },
  ];
};

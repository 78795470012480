import React, { useEffect, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { environment } from '~/environments';
import { UserInfo } from './UserInfo';

export const DeclineBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [searchParams] = useSearchParams();

  const declineBooking = async () => {
    setLoading(true);
    setError(false);
    try {
      await axios.get(
        `${environment.apiUrl}/allocations/decline/${searchParams.get(
          'f',
        )}/${searchParams.get('p')}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.response?.data === '[Allocation] Invalid link') {
        setError('Booking not available.');
      } else if (e?.response?.data === '[Allocation] Link no longer active') {
        setError('Booking request has already expired.');
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    declineBooking();
  }, [searchParams]);

  return (
    <UserInfo
      loading={loading}
      title={
        error
          ? error !== true
            ? error
            : 'Ooops, something went wrong...'
          : 'Thank you for your reply!'
      }
    >
      {error ? (
        <Grid item>
          <Typography
            variant="body1"
            sx={{
              width: { sm: 350, lg: 445 },
              fontFamily: 'Messina Sans',
              marginBottom: 20,
            }}
          >
            {error === 'Booking request has already expired.' &&
              'This booking inquiry is no longer valid or may have been already assigned. '}
            In case of any questions, please contact us at{' '}
            <Link
              href="mailto:operations@volubus.com"
              sx={{ color: '#ffffff' }}
            >
              operations@volubus.com
            </Link>{' '}
            or&nbsp;+46&nbsp;40&nbsp;668&nbsp;88&nbsp;87.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item sx={{ marginBottom: 3 }}>
            <Typography
              variant="body1"
              sx={{
                width: { sm: 350, lg: 445 },
                fontFamily: 'Messina Sans',
              }}
            >
              We are sorry to hear you don’t have availability for us, but thank
              you for your reply. We will keep sending you requests for the
              future.
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              sx={{
                width: { sm: 350, lg: 445 },
                fontFamily: 'Messina Sans',
                marginBottom: 20,
              }}
            >
              Should you be willing to share your feedback, please contact us at{' '}
              <Link
                href="mailto:operations@volubus.com"
                sx={{ color: '#ffffff' }}
              >
                operations@volubus.com
              </Link>{' '}
              or&nbsp;+46&nbsp;40&nbsp;668&nbsp;88&nbsp;87. Your opinion about
              our services is greatly appreciated.
            </Typography>
          </Grid>
        </>
      )}
    </UserInfo>
  );
};

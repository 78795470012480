import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Clear, Search } from '@mui/icons-material';
import { Box, IconButton, Input, InputProps } from '@mui/material';

type Props = {
  disabled?: boolean;
  placeholder?: string;
  initialValue?: string;
  onChange?: (value: string) => void;
  onRequestSearch?: (value: string) => void;
} & InputProps;

const SearchInputComponent = (
  {
    disabled = false,
    placeholder = 'Search',
    initialValue = '',
    onRequestSearch,
    onChange,
    ...inputProps
  }: Props,
  ref: React.Ref<Partial<InputProps>>,
) => {
  const inputRef = useRef<InputProps>(null);
  const hasMountedRef = useRef(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      inputProps.onFocus?.(event);
    },
    [inputProps.onFocus],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setValue((v) => v.trim());
      inputProps.onBlur?.(event);
    },
    [inputProps.onBlur],
  );

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onChange?.(event.target.value);

      const getData = setTimeout(() => {
        hasMountedRef.current = true;
        onRequestSearch?.(event.target.value);
      }, 1000);

      return () => clearTimeout(getData);
    },
    [onChange],
  );

  const handleCancel = () => {
    setValue('');
    onRequestSearch?.('');
  };

  const handleRequestSearch = useCallback(() => {
    onRequestSearch?.(value);
  }, [onRequestSearch, value]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleRequestSearch();
      } else if (event.key === 'Escape') {
        handleCancel();
      }
      inputProps.onKeyUp?.(event);
    },
    [handleRequestSearch, handleCancel, inputProps.onKeyUp],
  );

  useImperativeHandle(ref, () => ({
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
      inputRef?.current?.onFocus?.(event);
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      inputRef?.current?.onBlur?.(event);
    },
  }));

  return (
    <Box sx={{ position: 'relative' }}>
      <Input
        {...inputProps}
        placeholder={placeholder}
        inputRef={inputRef}
        onBlur={handleBlur}
        value={value}
        onChange={handleInput}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        fullWidth
        size="medium"
        disableUnderline
        disabled={disabled}
        sx={{ height: 43, width: 300 }}
      />
      <IconButton
        disabled={disabled}
        sx={{
          position: 'absolute',
          top: 0,
          right: 2,
          opacity: value === '' ? 1 : 0,
        }}
      >
        <Search />
      </IconButton>
      <IconButton
        onClick={handleCancel}
        disabled={disabled}
        sx={{
          position: 'absolute',
          top: 0,
          right: 2,
          opacity: value !== '' ? 1 : 0,
        }}
      >
        <Clear />
      </IconButton>
    </Box>
  );
};

export const SearchInput = React.forwardRef<InputProps, Props>(
  SearchInputComponent,
);

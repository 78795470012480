import {
  Location,
  LocationApi,
  LocationSupplier,
  LocationSupplierApi,
  StrategyType,
} from '~/types';

export const locationSupplierParser = (
  supplier: LocationSupplierApi,
): LocationSupplier => {
  return {
    id: supplier.id,
    locationId: supplier.location_id,
    supplierId: supplier.supplier_id,
    supplierSlug: supplier.supplier_slug,
    supplierName: supplier.supplier_name,
    supplierEmail: supplier.supplier_email,
    priority: supplier.priority,
    minPaxCount: supplier.min_pax_count,
    maxPaxCount: supplier.max_pax_count,
    isActive: supplier.is_active,
    quotePricesOnly: supplier.quote_prices_only,
  };
};

export const locationSuppliersParser = (
  suppliers: LocationSupplierApi[],
): LocationSupplier[] => {
  return suppliers.map((supplier) => locationSupplierParser(supplier));
};

export const locationParser = (location: LocationApi): Location => {
  return {
    id: location.id,
    city: location.name,
    country: location.country,
    coordinates: location.coordinates,
    radius: location.radius,
    strategy: location.strategy ?? StrategyType.NONE,
    ...(location.strategy_config && {
      strategyConfig: location.strategy_config,
    }),
    suppliers: locationSuppliersParser(location.suppliers),
    isActive: location.is_active,
  };
};

export const locationsParser = (locations: LocationApi[]): Location[] =>
  locations.map((location) => locationParser(location));

export type TableColumn<T> = {
  name: keyof T | string;
  label: string;
  minWidth?: number;
  width?: number;
  sortColumn?: string;
};

export enum TableOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '~/store';
import { getSettings } from './settingsSlice';

export const Settings = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return null;
};

import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { DATETIME_FORMAT } from '~/constants';
import { Booking, BookingApi } from '~/types';
import { clientParser } from './client';
import { supplierParser } from './supplier';

export const bookingParser = (booking: BookingApi): Booking => {
  const date = new Date(booking.date + 'Z');
  const pickupLocalDate = toDate(booking.pickup_local_date, {
    timeZone: booking.pickup_time_zone,
  });
  return {
    id: booking.id,
    bookingId: booking.booking_id,
    ...(booking.service_class && { serviceClass: booking.service_class }),
    ...(booking.service_category && {
      serviceCategory: booking.service_category,
    }),
    ...(booking.vehicle_class && { vehicleClass: booking.vehicle_class }),
    passengerCount: booking.passenger_count,
    luggageCount: booking.luggage_count,
    carryOnLuggageCount: booking.carry_on_count,
    date,
    dateFormatted: format(date, DATETIME_FORMAT),
    pickupTimeZone: booking.pickup_time_zone,
    pickupLocalDate,
    pickupLocalDateFormatted: format(
      utcToZonedTime(pickupLocalDate, booking.pickup_time_zone),
      DATETIME_FORMAT,
      {
        timeZone: booking.pickup_time_zone,
      },
    ),
    pickupLocation: booking.pickup_location,
    ...(booking.pickup_coordinates && {
      pickupCoordinates: booking.pickup_coordinates,
    }),
    distance: booking.distance,
    duration: booking.duration,
    destination: booking.destination,
    ...(booking.destination_coordinates && {
      destinationCoordinates: booking.destination_coordinates,
    }),
    ...(booking.supplier && { supplier: supplierParser(booking.supplier) }),
    grossSupplierPrice: booking.gross_supplier_price,
    client: clientParser(booking.client),
    clientCurrency: booking.client_currency,
    grossClientPrice: booking.gross_client_price,
    status: booking.status,
    passengerName: booking.passenger_name,
    passengerEmail: booking.passenger_email,
    passengerTelephone: booking.passenger_telephone,
    ...(booking.pickup_country && { pickupCountry: booking.pickup_country }),
    ...(booking.destination_country && {
      destinationCountry: booking.destination_country,
    }),
    ...(booking.pickup_city && { pickupCity: booking.pickup_city }),
    ...(booking.destination_city && {
      destinationCity: booking.destination_city,
    }),
    comment: booking.comment || '',
    clientAdditionalRequests: booking.client_additional_requests || '',
    ...(booking.driver_telephone && {
      driverTelephone: booking.driver_telephone,
    }),
    ...(booking.driver_name && { driverName: booking.driver_name }),
    flightNumber: booking.flight_number,
    isCalculatedClientPrice: booking.is_calculated_client_price,
    ...(booking.return_booking && {
      returnBooking: {
        id: booking.return_booking.id,
        bookingId: booking.return_booking.booking_id,
      },
    }),
    ...(booking.duplicated_booking && {
      duplicatedBooking: {
        id: booking.duplicated_booking.id,
        bookingId: booking.duplicated_booking.booking_id,
      },
    }),
    author: booking.author,
    createdAt: new Date(booking.created_at + 'Z'),
    updatedAt: new Date(booking.updated_at + 'Z'),
  };
};

export const bookingsParser = (bookings: BookingApi[]): Booking[] =>
  bookings.map((booking) => bookingParser(booking));

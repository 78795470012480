import { createTheme, ThemeOptions } from '@mui/material/styles';

const defaultTheme = createTheme();

export const theme = createTheme({
  palette: {
    background: {
      default: '#f9f9f9',
    },
    primary: {
      main: '#5aaaff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#838f9c',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: 36,
      paddingBottom: 8,
    },
    h2: {
      fontSize: 24,
      fontWeight: 'bold',
      paddingBottom: 8,
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingBottom: 4,
    },
    h4: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 4,
    },
    h5: {
      fontSize: 16,
      fontWeight: 'bold',
      paddingBottom: 4,
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
      paddingBottom: 4,
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
    },
  },
} as ThemeOptions);

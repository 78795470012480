import React, { useContext } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Warning } from '@mui/icons-material';
import { AppBar, Box, Toolbar, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

import { VolubusLogoBlue } from '~/assets';
import type { RootState } from '~/store';

import { SIDEBAR_WIDTH_CLOSED, SIDEBAR_WIDTH_OPEN } from '../constants';
import { LayoutContext } from '../LayoutProvider';
import { UserMenu } from './UserMenu';

export const Navbar = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { isSidebarOpen } = useContext(LayoutContext);
  const { settings, loading: loadingSettings } = useSelector(
    (state: RootState) => state.settings,
  );

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={(theme) => ({
        paddingLeft: isSidebarOpen
          ? `${SIDEBAR_WIDTH_OPEN}px`
          : `${SIDEBAR_WIDTH_CLOSED}px`,
        transition: theme.transitions.create('padding', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      })}
    >
      <Toolbar>
        <img
          src={VolubusLogoBlue}
          alt="Volubus Logo"
          style={{ width: '200px' }}
        />
        <Box sx={{ flexGrow: 1 }} />

        {!loadingSettings && !settings?.automaticAllocation && (
          <Tooltip
            title="Automatic allocation is currently disabled!"
            placement="left"
          >
            <Warning color="error" />
          </Tooltip>
        )}
        {!!user && <UserMenu />}
      </Toolbar>
    </AppBar>
  );
};

import React from 'react';
import { Authenticator, View } from '@aws-amplify/ui-react';
import { ReactProps } from '~/types';

export const AuthProvider = ({ children }: ReactProps) => {
  return (
    <Authenticator.Provider>
      <View>{children}</View>
    </Authenticator.Provider>
  );
};

import { Confirmation, TableColumn } from '~/types';

export const getAllocationRequestTableColumns =
  (): TableColumn<Confirmation>[] => {
    return [
      {
        name: 'id',
        label: 'ID',
        sortColumn: 'id',
      },
      {
        name: 'bookingId',
        label: 'Booking',
        sortColumn: 'booking_id',
      },
      {
        name: 'supplierId',
        label: 'Supplier',
        sortColumn: 'supplier_id',
      },
      {
        name: 'status',
        label: 'Status',
        sortColumn: 'status',
      },
      {
        name: 'sentAtFormatted',
        label: 'Sent At',
        sortColumn: 'sent_at',
      },
      {
        name: 'updatedAt',
        label: 'Updated At',
        sortColumn: 'updated_at',
      },
      {
        name: 'validUntilFormatted',
        label: 'Valid Until',
        sortColumn: 'valid_until',
      },
      {
        name: 'initiator',
        label: 'Sent By',
        sortColumn: 'initiator',
      },
      {
        name: 'price',
        label: 'Price',
        sortColumn: 'price',
      },
      {
        name: 'actions',
        label: '',
        width: 100,
      },
    ];
  };

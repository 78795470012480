import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import { useToggle } from '~/hooks';
import { Location } from '~/types';
import {
  AddLocation,
  EditLocation,
  LocationTable,
  RemoveLocation,
} from './components';

export const Locations = () => {
  const [triggerFetchLocations, fetchLocations] = useToggle();
  const [isAddingLocation, toggleAddingLocation] = useToggle();
  const [isEditingLocation, toggleEditingLocation] = useState<Location | null>(
    null,
  );
  const [isRemovingLocation, toggleRemovingLocation] = useState<
    Location['id'] | null
  >(null);

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">Locations</Typography>
            </Grid>

            <Grid item sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleAddingLocation(true)}
              >
                Add location
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ height: 'calc(100% - 55px - 34px)', width: '100%' }}>
          <LocationTable
            triggerFetchLocations={triggerFetchLocations}
            onEdit={(location) => toggleEditingLocation(location)}
            onRemove={(locationId) => toggleRemovingLocation(locationId)}
          />
        </Grid>
      </Grid>

      {isAddingLocation && (
        <AddLocation
          onSuccess={fetchLocations}
          onClose={() => toggleAddingLocation(false)}
        />
      )}

      {isEditingLocation && (
        <EditLocation
          location={isEditingLocation}
          onSuccess={fetchLocations}
          onClose={() => toggleEditingLocation(null)}
        />
      )}

      {isRemovingLocation && (
        <RemoveLocation
          locationId={isRemovingLocation}
          onSuccess={fetchLocations}
          onClose={() => toggleRemovingLocation(null)}
        />
      )}
    </Box>
  );
};

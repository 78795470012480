export type Strategy = {
  id: number;
  name: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: Record<string, any>;
};

export type StrategyInput = {
  id: number;
  name: string;
  description: string;
  config: string;
};

export enum StrategyType {
  'NONE' = 0,
  'QUEUE' = 1,
  'SEND_OUT' = 2,
  'WE_SKI' = 3,
  'PRICE' = 4,
}

export const StrategyLabels: Record<StrategyType, string> = {
  [StrategyType.NONE]: 'None',
  [StrategyType.QUEUE]: 'Queue',
  [StrategyType.SEND_OUT]: 'Send Out',
  [StrategyType.WE_SKI]: 'WeSki',
  [StrategyType.PRICE]: 'Price Based',
};

import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { Location } from '~/types';
import { api } from '~/utils';

type Props = {
  locationId: Location['id'];
  onSuccess: () => void;
  onClose: () => void;
};

export const RemoveLocation = ({ locationId, onSuccess, onClose }: Props) => {
  const handleRemove = async () => {
    try {
      await api.delete(`/locations/${locationId}`);
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error removing location');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="xs">
      <DialogTitle variant="h2">Really?</DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleRemove}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

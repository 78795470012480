import React from 'react';
import { Dialog } from '@mui/material';

import { Location, LocationSupplier, LocationSupplierInput } from '~/types';
import { api } from '~/utils';

import { EditSupplierDialog } from './EditSupplierDialog';

type Props = {
  locationId: Location['id'];
  supplier: LocationSupplier;
  onSuccess: () => void;
  onClose: () => void;
};

export const EditSupplier = ({
  locationId,
  supplier,
  onSuccess,
  onClose,
}: Props) => {
  const handleSubmit = async (data: LocationSupplierInput) => {
    try {
      await api.patch(
        `/locations/${locationId}/suppliers/${supplier.supplierId}`,
        {
          id: supplier.supplierId,
          priority: data.priority,
          min_pax_count: data.minPaxCount,
          max_pax_count: data.maxPaxCount,
          is_active: data.isActive,
          quote_prices_only: data.quotePricesOnly,
        },
      );
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error adding location supplier');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <EditSupplierDialog
        supplier={supplier}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

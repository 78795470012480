import React from 'react';
import { ThemeProvider as AmplifyThemeProvider } from '@aws-amplify/ui-react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactProps } from '~/types';
import { theme as amplifyTheme } from './amplifyTheme';
import { theme as muiTheme } from './muiTheme';

export const ThemeProvider = ({ children }: ReactProps) => {
  return (
    <AmplifyThemeProvider theme={amplifyTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </AmplifyThemeProvider>
  );
};

import { Client, ClientApi } from './Client';
import { ServiceCategory } from './common';
import { Coordinates } from './Coordinates';
import { Country } from './Country';
import { Currency } from './Currency';
import { Supplier, SupplierApi } from './Supplier';
import { Tag } from './Tag';

export enum ServiceClass {
  HOURLY = 'HOURLY',
  TRANSFER = 'TRANSFER',
  CUSTOM = 'CUSTOM',
}

export enum VehicleClass {
  SEDAN = 'SEDAN',
  VAN = 'VAN',
  STANDARD_MINIBUS = 'STANDARD_MINIBUS',
  STANDARD_MINI_COACH = 'STANDARD_MINI_COACH',
  STANDARD_MIDI_COACH = 'STANDARD_MIDI_COACH',
  STANDARD_MAXI_COACH = 'STANDARD_MAXI_COACH',
  MULTIPLE = 'MULTIPLE',
}

export enum BookingStatus {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  CHARGE_CANCELED = 'CHARGE_CANCELED',
  ONGOING_ALLOCATION = 'ONGOING_ALLOCATION',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  REQUIRED_MANUAL_ALLOCATION = 'REQUIRED_MANUAL_ALLOCATION',
  DRIVER_NO_SHOW = 'DRIVER_NO_SHOW',
  CUSTOMER_NO_SHOW = 'CUSTOMER_NO_SHOW',
  PENDING_REVIEW = 'PENDING_REVIEW',
  NO_CHARGE_CANCELED = 'NO_CHARGE_CANCELED',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
}

export enum SupplierPriceType {
  NET = 'NET',
  GROSS = 'GROSS',
  CUSTOM = 'CUSTOM',
}

export enum ClientPriceType {
  GROSS = 'GROSS',
  CUSTOM = 'CUSTOM',
  NO_VAT = 'NO_VAT',
}

export enum BookingSource {
  ADMIN_UI = 'ADMIN_UI',
  CLIENT_UI = 'CLIENT_UI ',
  EMAIL_SCRAPER = 'EMAIL_SCRAPER',
}

export enum BookingRouteService {
  AP = 'AP',
  LD = 'LD',
  CC = 'CC',
  HRLY = 'HRLY',
  LTL = 'LTL',
  SUUP = 'SUUP',
  KMUP = 'KMUP',
  LDHR = 'LDHR',
}

export enum BookingRouteType {
  BASIC = 'BASIC',
  FINAL = 'FINAL',
}

export enum BookingPriceHistoryType {
  CANCELLATION = 'CANCELLATION',
  REFUND = 'REFUND',
}

export type AdminApi = {
  id: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
};

export type Admin = {
  id: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
};

export type BookingStopApi = {
  id: number;
  address: string;
};

export type BookingStop = {
  id: number;
  address: string;
};

export type BookingStatusHistoryApi = {
  status: BookingStatus;
  author: string;
  timestamp: string;
};

export type BookingPriceHistoryApi = {
  gross_price: string;
  net_price: string;
  currency: Currency;
  type: BookingPriceHistoryType;
  vat: number;
  author: string;
  is_reclaimable: boolean;
  timestamp: string;
};

export type BookingBaseApi = {
  id: number;
  booking_id: string;
};

export type BookingApi = BookingBaseApi & {
  service_class: ServiceClass | null;
  service_category: ServiceCategory | null;
  vehicle_class: VehicleClass | null;
  passenger_count: number;
  luggage_count: number;
  carry_on_count: number;
  is_send_reminder_email: boolean;
  stops: BookingStopApi[];
  top_ups: unknown;
  date: string;
  pickup_time_zone: string;
  pickup_local_date: string;
  pickup_location: string;
  pickup_region: string;
  pickup_coordinates: Coordinates | null;
  distance: number;
  duration: number;
  destination: string;
  destination_region: string;
  internal_invoice: string;
  client_reference_number: string;
  meet_and_greet: string;
  destination_coordinates: Coordinates | null;
  supplier: SupplierApi | null;
  client: ClientApi;
  client_price: number;
  status: BookingStatus;
  passenger_name: string;
  passenger_email: string;
  passenger_telephone: string;
  pickup_country: Country | null;
  destination_country: Country | null;
  pickup_city: string | null;
  destination_city: string | null;
  comment: string | null;
  client_additional_requests: string | null;
  driver_telephone: string | null;
  driver_name: string | null;
  flight_number: string;
  is_calculated_client_price: boolean;
  return_booking: BookingBaseApi | null;
  children: BookingBaseApi[];
  duplicated_booking: BookingBaseApi | null;
  net_supplier_price: number;
  gross_supplier_price: number;
  net_client_price: number;
  gross_client_price: number;
  supplier_price_type: SupplierPriceType;
  client_price_type: ClientPriceType;
  source: BookingSource;
  client_price_vat: number;
  is_client_vat_reclaimable: boolean;
  supplier_price_vat: number;
  admins: AdminApi[];
  author: string;
  is_send_reminder_sms: boolean;
  client_currency: Currency;
  supplier_currency: Currency;
  consents: string;
  tags: Tag[];
  route_price: number;
  route_service: BookingRouteService;
  route_type: BookingRouteType;
  seasonality_fee: number;
  zone_fee: number;
  margin: number;
  commission: number;
  quotation_price: number;
  quotation_service: BookingRouteService;
  status_history: BookingStatusHistoryApi[];
  client_prices_history: BookingPriceHistoryApi[];
  supplier_prices_history: BookingPriceHistoryApi[];
  sent_confirmation: string;
  sent_cancellation: string;
  created_at: string;
  updated_at: string;
};

export type BookingBase = {
  id: number;
  bookingId: string;
};

export type Booking = BookingBase & {
  serviceClass?: ServiceClass;
  serviceCategory?: ServiceCategory;
  vehicleClass?: VehicleClass;
  passengerCount: number;
  luggageCount: number;
  carryOnLuggageCount: number;
  date: Date;
  dateFormatted: string;
  pickupTimeZone: string;
  pickupLocalDate: Date;
  pickupLocalDateFormatted: string;
  pickupLocation: string;
  pickupCoordinates?: Coordinates;
  distance: number;
  duration: number;
  destination: string;
  destinationCoordinates?: Coordinates;
  supplier?: Supplier;
  grossSupplierPrice: number;
  client: Client;
  clientCurrency: Currency;
  grossClientPrice: number;
  status: BookingStatus;
  passengerName: string;
  passengerEmail: string;
  passengerTelephone: string;
  pickupCountry?: Country;
  destinationCountry?: Country;
  pickupCity?: string;
  destinationCity?: string;
  comment: string;
  clientAdditionalRequests: string;
  driverTelephone?: string;
  driverName?: string;
  flightNumber: string;
  isCalculatedClientPrice: boolean;
  returnBooking?: BookingBase;
  duplicatedBooking?: BookingBase;
  author: string;
  // stops: BookingStop[];
  createdAt: Date;
  updatedAt: Date;
};

import React, { useContext } from 'react';
import {
  AirplaneTicket,
  AirportShuttle,
  FactCheck,
  ListAlt,
  Menu,
  PinDrop,
  Psychology,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { CSSObject, Theme } from '@mui/material/styles';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { NAVBAR_HEIGHT, SIDEBAR_WIDTH_OPEN } from '../constants';
import { LayoutContext } from '../LayoutProvider';

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDEBAR_WIDTH_OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const listItems = [
  {
    icon: <ListAlt />,
    label: 'Bookings',
    path: '/bookings',
  },
  {
    icon: <PinDrop />,
    label: 'Locations',
    path: '/locations',
  },
  {
    icon: <Psychology />,
    label: 'Strategies',
    path: '/strategies',
  },
  {
    icon: <FactCheck />,
    label: 'Requests',
    path: '/allocation-requests',
  },
  {
    icon: <AirplaneTicket />,
    label: 'Clients',
    path: '/clients',
  },
  {
    icon: <AirportShuttle />,
    label: 'Suppliers',
    path: '/suppliers',
  },
];

export const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useContext(LayoutContext);
  const { pathname } = useLocation();

  return (
    <Drawer
      variant="permanent"
      open={isSidebarOpen}
      sx={(theme) => ({
        flexShrink: 0,
        ...(isSidebarOpen && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!isSidebarOpen && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: `calc(${theme.spacing(7)} + 1px)`,
          [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
          },
          height: NAVBAR_HEIGHT,
        })}
      >
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar()}
          edge="start"
          sx={{ margin: 1 }}
        >
          <Menu />
        </IconButton>
      </Box>
      <List>
        {listItems.map((listItem, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={listItem.path}
              sx={{
                px: '18px',
                color: matchPath({ path: listItem.path, end: false }, pathname)
                  ? 'primary.main'
                  : 'inherit',
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                {listItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={listItem.label}
                sx={(theme) => ({
                  opacity: isSidebarOpen ? 1 : 0,
                  transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                })}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List sx={{ marginTop: 'auto' }}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/settings"
            sx={{
              px: '18px',
              color: matchPath({ path: '/settings', end: false }, pathname)
                ? 'primary.main'
                : 'inherit',
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              <Settings />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              sx={(theme) => ({
                opacity: isSidebarOpen ? 1 : 0,
                transition: theme.transitions.create('opacity', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              })}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

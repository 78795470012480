import React from 'react';
import { Chip } from '@mui/material';
import { BookingStatus } from '~/types';

const BookingStatusLabel: Record<BookingStatus, string> = {
  [BookingStatus.CREATED]: 'Created',
  [BookingStatus.ASSIGNED]: 'Assigned',
  [BookingStatus.COMPLETED]: 'Completed',
  [BookingStatus.CHARGE_CANCELED]: 'Cancelled with charge',
  [BookingStatus.ONGOING_ALLOCATION]: 'Ongoing',
  [BookingStatus.WAITING_FOR_CONFIRMATION]: 'Confirming',
  [BookingStatus.REQUIRED_MANUAL_ALLOCATION]: 'Manual',
  [BookingStatus.DRIVER_NO_SHOW]: 'Driver No Show',
  [BookingStatus.CUSTOMER_NO_SHOW]: 'Customer No Show',
  [BookingStatus.PENDING_REVIEW]: 'Pending review',
  [BookingStatus.NO_CHARGE_CANCELED]: 'Cancelled with no charge',
  [BookingStatus.REQUEST_REJECTED]: 'Request rejected',
};

const BookingStatusColor: Record<BookingStatus, string> = {
  [BookingStatus.CREATED]: 'primary.main',
  [BookingStatus.ASSIGNED]: 'warning.light',
  [BookingStatus.COMPLETED]: 'success.main',
  [BookingStatus.CHARGE_CANCELED]: 'secondary.main',
  [BookingStatus.ONGOING_ALLOCATION]: '#945623',
  [BookingStatus.WAITING_FOR_CONFIRMATION]: '#FFD500',
  [BookingStatus.REQUIRED_MANUAL_ALLOCATION]: 'error.light',
  [BookingStatus.DRIVER_NO_SHOW]: 'error.main',
  [BookingStatus.CUSTOMER_NO_SHOW]: 'error.main',
  [BookingStatus.PENDING_REVIEW]: '#FFD500',
  [BookingStatus.NO_CHARGE_CANCELED]: 'secondary.main',
  [BookingStatus.REQUEST_REJECTED]: 'secondary.main',
};

type Props = {
  status: BookingStatus;
};

export const Status = ({ status }: Props) => {
  return (
    <Chip
      label={BookingStatusLabel[status]}
      component="span"
      sx={{
        color: 'white',
        backgroundColor: BookingStatusColor[status],
        width: '100%',
      }}
    />
  );
};

import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactProps } from '~/types';
import { Settings } from '../Settings';
import { Navbar, Sidebar } from './components';
import { NAVBAR_HEIGHT } from './constants';

export const Layout = ({ children }: ReactProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { route, authStatus } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (authStatus === 'configuring' && route === 'setup') {
      navigate('/login', { state: { from: location } });
    }
  }, [route, authStatus]);

  return (
    <Box display="flex">
      <Settings />
      <Navbar />
      <Sidebar />
      <Box
        component="main"
        sx={{
          marginTop: `${NAVBAR_HEIGHT}px`,
          flexGrow: 1,
          height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
          overflowY: 'auto',
        }}
      >
        {route === 'authenticated' && children}
      </Box>
    </Box>
  );
};

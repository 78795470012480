import React, { useEffect, useState } from 'react';
import { Clear, Launch } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { environment } from '~/environments';
import { useToggle } from '~/hooks';
import { Booking, BookingStatus, Confirmation, ConfirmationApi } from '~/types';
import { confirmationsParser, api } from '~/utils';

import { SendRequest } from './SendRequest';

type Props = {
  booking: Booking;
};

const AllowedStatuses = [
  BookingStatus.CREATED,
  BookingStatus.ONGOING_ALLOCATION,
  BookingStatus.REQUIRED_MANUAL_ALLOCATION,
];

export const AllocationRequests = ({ booking }: Props) => {
  const [loading, setLoading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [confirmations, setConfirmations] = useState<Confirmation[]>([]);
  const [isSendingRequest, toggleSendingRequest] = useToggle();

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await api.get<ConfirmationApi[]>(
        `/bookings/${booking.id}/requests`,
      );
      setConfirmations(confirmationsParser(response.data));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const handleCancel = async (requestId: string) => {
    setCancelling(true);
    try {
      await api.post(`/requests/${requestId}/cancel`, {
        cancelled_at: new Date().toUTCString(),
        status_id: 5,
      });
      fetchRequests();
    } catch (e) {
      console.log(e);
    }
    setCancelling(false);
  };

  const handleClose = () => {
    toggleSendingRequest(false);
    fetchRequests();
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Allocation requests"
          action={
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!AllowedStatuses.includes(booking.status)}
              onClick={() => toggleSendingRequest(true)}
            >
              Send allocation request
            </Button>
          }
        />
        <CardContent
          sx={{
            minHeight: 200,
            overflow: 'auto',
            padding: 0,
            paddingBottom: 2,
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Booking</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Sent At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Valid Until</TableCell>
                  <TableCell>Sent By</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Cancel Request</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {confirmations.map((confirmation) => {
                  return (
                    <TableRow key={confirmation.id}>
                      <TableCell>{confirmation.id}</TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={'/bookings/' + confirmation.bookingId}
                        >
                          {confirmation.bookingId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Box
                          component="span"
                          sx={{
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        >
                          {confirmation.supplierName || confirmation.supplierId}
                          <Link
                            href={
                              environment.adminUrl +
                              '/suppliers/' +
                              confirmation.supplierId
                            }
                            target="_blank"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Launch fontSize="small" sx={{ marginLeft: 0.5 }} />
                          </Link>
                        </Box>
                      </TableCell>
                      <TableCell>{confirmation.status}</TableCell>
                      <TableCell>{confirmation.sentAtFormatted}</TableCell>
                      <TableCell>
                        {confirmation.confirmedAtFormatted ||
                          confirmation.declinedAtFormatted ||
                          confirmation.cancelledAtFormatted}
                      </TableCell>
                      <TableCell>{confirmation.validUntilFormatted}</TableCell>
                      <TableCell>{confirmation.initiator}</TableCell>
                      <TableCell>{confirmation.price}</TableCell>
                      <TableCell>
                        {confirmation.status === 'SENT' && (
                          <IconButton
                            color="error"
                            disabled={cancelling}
                            onClick={() => handleCancel(confirmation.id)}
                          >
                            <Clear />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      {isSendingRequest && (
        <SendRequest booking={booking} onClose={handleClose} />
      )}
    </>
  );
};

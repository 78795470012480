import { ServiceCategory } from './common';
import { Country } from './Country';
import { Currency } from './Currency';
import { Garage } from './Garage';

export enum CompanyCategory {
  COACH = 'COACH',
  CHAUFFEUR = 'CHAUFFEUR',
  COACH_AND_CHAUFFEUR = 'COACH_AND_CHAUFFEUR',
  DMC = 'DMC',
}

export enum CooperationStatus {
  NONE = 'NONE',
  PREFERRED_PARTNER = 'PREFERRED_PARTNER',
  DEACTIVATED = 'DEACTIVATED',
  NEW = 'NEW',
  ONBOARDING = 'ONBOARDING',
  TESTED = 'TESTED',
}

export enum PriceSheetStatus {
  OWN_PRICE_SHEET = 'OWN_PRICE_SHEET',
  OUR_PRICE_SHEET = 'OUR_PRICE_SHEET',
  NO_PRICE_SHEET = 'NO_PRICE_SHEET',
}

export enum ResponseRate {
  FAST = 'FAST',
  MEDIUM = 'MEDIUM',
  SLOW = 'SLOW',
  NOT_RESPONDING = 'NOT_RESPONDING',
}

export type SupplierBaseApi = {
  id: number;
  supplier_id: string;
  name: string;
};

export type SupplierApi = SupplierBaseApi & {
  website: string | null;
  email: string | null;
  telephone: string | null;
  person: string | null;
  comment: string | null;
  city: string;
  currency: Currency;
  conversion_rate: string | null;
  country: Country;
  quality_comment: string | null;
  entity_name: string;
  garages: Garage[];
  company_category: CompanyCategory | null;
  service_category: ServiceCategory | null;
  cooperation_status: CooperationStatus | null;
  price_sheet_status: PriceSheetStatus | null;
  response_rate: ResponseRate | null;
  postal_code: string | null;
  domain: string | null;
  vat: number | null;
  author: string;
  is_archived: boolean;
  number_of_quotation_items: number;
  latest_quotation_item_date: string | null;
  rating: number | null;
};

export type SupplierBase = {
  id: number;
  supplierId: string;
  name: string;
};

export type Supplier = SupplierBase & {
  website?: string;
  email?: string;
  telephone?: string;
  person?: string;
  comment?: string;
  city: string;
  currency: Currency;
  conversionRate?: string;
  country: Country;
  qualityComment?: string;
  entityName: string;
  garages: Garage[];
  companyCategory?: CompanyCategory;
  serviceCategory?: ServiceCategory;
  cooperationStatus?: CooperationStatus;
  pricesheetStatus?: PriceSheetStatus;
  responseRate?: ResponseRate;
  postalCode?: string;
  domain?: string;
  vat?: number;
  author: string;
  isArchived: boolean;
  numberOfQuotationItems: number;
  latestQuotationItemDate?: Date;
  latestQuotationItemDateFormatted?: string;
  rating?: number;
};

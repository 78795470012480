import React from 'react';
import { Dialog } from '@mui/material';

import { Location, LocationSupplierInput } from '~/types';
import { api } from '~/utils';

import { AddSupplierDialog } from './AddSupplierDialog';

type Props = {
  locationId: Location['id'];
  onSuccess: () => void;
  onClose: () => void;
};

export const AddSupplier = ({ locationId, onSuccess, onClose }: Props) => {
  const handleSubmit = async (data: LocationSupplierInput) => {
    try {
      await api.post(`/locations/${locationId}/suppliers`, {
        id: data.supplier.value,
        priority: data.priority,
        min_pax_count: data.minPaxCount,
        max_pax_count: data.maxPaxCount,
        is_active: data.isActive,
        quote_prices_only: data.quotePricesOnly,
      });
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error adding location supplier');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <AddSupplierDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};

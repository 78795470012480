import { format } from 'date-fns-tz';
import { DATETIME_FORMAT } from '~/constants';
import { Supplier, SupplierApi } from '~/types';

export const supplierParser = (supplier: SupplierApi): Supplier => {
  const latestQuotationItemDate = new Date(
    supplier.latest_quotation_item_date + 'Z',
  );
  return {
    id: supplier.id,
    supplierId: supplier.supplier_id,
    name: supplier.name,
    ...(supplier.website && { website: supplier.website }),
    ...(supplier.email && { email: supplier.email }),
    ...(supplier.telephone && { telephone: supplier.telephone }),
    ...(supplier.person && { person: supplier.person }),
    ...(supplier.comment && { comment: supplier.comment }),
    city: supplier.city,
    currency: supplier.currency,
    ...(supplier.conversion_rate && {
      conversionRate: supplier.conversion_rate,
    }),
    country: supplier.country,
    ...(supplier.quality_comment && {
      qualityComment: supplier.quality_comment,
    }),
    entityName: supplier.entity_name,
    garages: supplier.garages,
    ...(supplier.company_category && {
      companyCategory: supplier.company_category,
    }),
    ...(supplier.service_category && {
      serviceCategory: supplier.service_category,
    }),
    ...(supplier.cooperation_status && {
      cooperationStatus: supplier.cooperation_status,
    }),
    ...(supplier.price_sheet_status && {
      pricesheetStatus: supplier.price_sheet_status,
    }),
    ...(supplier.response_rate && { responseRate: supplier.response_rate }),
    ...(supplier.postal_code && { postalCode: supplier.postal_code }),
    ...(supplier.domain && { domain: supplier.domain }),
    ...(supplier.vat && { vat: supplier.vat }),
    author: supplier.author,
    isArchived: supplier.is_archived,
    numberOfQuotationItems: supplier.number_of_quotation_items,
    ...(supplier.latest_quotation_item_date && { latestQuotationItemDate }),
    ...(supplier.latest_quotation_item_date && {
      latestQuotationItemDateFormatted: format(
        latestQuotationItemDate,
        DATETIME_FORMAT,
      ),
    }),
    ...(supplier.rating && { rating: supplier.rating }),
  };
};

export const suppliersParser = (suppliers: SupplierApi[]): Supplier[] =>
  suppliers.map((supplier) => supplierParser(supplier));

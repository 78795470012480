import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import { differenceInMinutes } from 'date-fns';

import { environment } from '../environments';

export const api = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const diff = differenceInMinutes(
      cognitoUser.getSignInUserSession()?.getAccessToken().getExpiration() *
        1000,
      new Date(),
    );
    if (diff <= 5) {
      const { refreshToken } = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(refreshToken, () => {
        // do something with the new session
      });
    }
  } catch (err) {
    console.error(err);
  }
};

api.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    await refreshToken();
    const user = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
    };

    return config as AxiosRequestConfig;
  },
);

import React, { useEffect, useState } from 'react';
import { Clear, Launch } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Loading } from '~/components';
import { environment } from '~/environments';
import { usePagination } from '~/hooks';
import {
  ApiListResponse,
  Confirmation,
  ConfirmationApi,
  TableColumn,
  TableOrder,
} from '~/types';
import { api, confirmationsParser } from '~/utils';

import { getAllocationRequestTableColumns } from './getAllocationRequestTableColumns';

type Props = {
  triggerFetchRequests: boolean;
  onCancel: (request: Confirmation) => void;
};

export const AllocationRequestTable = ({
  triggerFetchRequests,
  onCancel,
}: Props) => {
  const columns = getAllocationRequestTableColumns();

  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState<Confirmation[]>([]);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<TableOrder>(TableOrder.DESCENDING);
  const [orderBy, setOrderBy] = useState('id');
  const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
    usePagination();

  useEffect(() => {
    const controller = new AbortController();

    const fetchRequests = async () => {
      setLoading(true);

      try {
        const response = await api.get<ApiListResponse<ConfirmationApi>>(
          `/requests?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
          }&sort=${orderBy}-${order}`,
          {
            signal: controller.signal,
          },
        );
        setRequests(confirmationsParser(response.data?.data || []));
        setTotal(response.data?.total || 1);
      } catch (e) {
        if (!controller.signal.aborted) {
          console.error(e);
        }
      }

      if (!controller.signal.aborted) {
        setLoading(false);
      }
    };

    fetchRequests();

    return () => {
      controller.abort();
    };
  }, [triggerFetchRequests, page, rowsPerPage, order, orderBy]);

  const renderCell = (
    request: Confirmation,
    cell: TableColumn<Confirmation>['name'],
  ) => {
    switch (cell) {
      case 'bookingId':
        return (
          <TableCell key={cell}>
            <Link component={RouterLink} to={'/bookings/' + request.bookingId}>
              {request.bookingId}
            </Link>
          </TableCell>
        );
      case 'supplierId':
        return (
          <TableCell key={cell}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignContent: 'center',
              }}
            >
              {request.supplierName || request.supplierId}
              <Link
                href={environment.adminUrl + '/suppliers/' + request.supplierId}
                target="_blank"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Launch fontSize="small" sx={{ marginLeft: 0.5 }} />
              </Link>
            </Box>
          </TableCell>
        );
      case 'actions':
        return (
          <TableCell key={cell}>
            {request.status === 'SENT' && (
              <IconButton color="error" onClick={() => onCancel(request)}>
                <Clear />
              </IconButton>
            )}
          </TableCell>
        );
      case 'updatedAt':
        return (
          <TableCell key={cell}>
            {request.confirmedAtFormatted ||
              request.declinedAtFormatted ||
              request.cancelledAtFormatted}
          </TableCell>
        );
      default:
        return (
          <TableCell key={cell}>
            {request[cell as keyof Confirmation] as string}
          </TableCell>
        );
    }
  };

  const handleSort = (column: TableColumn<Confirmation>) => {
    const isDesc =
      orderBy === column.sortColumn && order === TableOrder.DESCENDING;
    setOrder(isDesc ? TableOrder.ASCENDING : TableOrder.DESCENDING);
    column.sortColumn && setOrderBy(column.sortColumn);
    handleChangePage(null, 0);
  };

  return (
    <>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortColumn ? (
                  <TableCell
                    key={column.name}
                    sx={{
                      minWidth: column.minWidth ?? 0,
                      ...(column.width && { width: column.width }),
                      whiteSpace: 'nowrap',
                    }}
                    sortDirection={
                      orderBy === column.sortColumn ? order : false
                    }
                    size="small"
                  >
                    <TableSortLabel
                      active={orderBy === column.sortColumn}
                      direction={
                        orderBy === column.sortColumn
                          ? order
                          : TableOrder.ASCENDING
                      }
                      onClick={() => handleSort(column)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.name}
                    sx={{
                      minWidth: column.minWidth ?? 0,
                      ...(column.width && { width: column.width }),
                    }}
                    size="small"
                  >
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: '100%' }}>
            {loading ? (
              <TableRow sx={{ height: '100%' }}>
                <TableCell colSpan={columns.length}>
                  <Loading message="Loading locations..." />
                </TableCell>
              </TableRow>
            ) : (
              requests.map((Confirmation) => (
                <TableRow key={Confirmation.id}>
                  {columns.map((column) =>
                    renderCell(Confirmation, column.name),
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={total}
        showFirstButton={true}
        showLastButton={true}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

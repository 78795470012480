import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Settings as SettingsType } from '~/types';
import { api, settingsParser } from '~/utils';

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async () => {
    const response = await api.get('/settings');
    return response.data;
  },
);

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (data: Partial<SettingsType>) => {
    const response = await api.patch('/settings', {
      automatic_allocation: data.automaticAllocation,
      default_strategy: data.defaultStrategy,
    });
    return response.data;
  },
);

export type SettingsState = {
  settings?: SettingsType;
  loading: boolean;
};

const initialState: SettingsState = {
  settings: undefined,
  loading: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [getSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [getSettings.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.settings = settingsParser(action.payload);
    },
    [getSettings.rejected.type]: (state) => {
      state.loading = false;
    },

    [updateSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [updateSettings.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.settings = settingsParser(action.payload);
    },
    [updateSettings.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const settingsReducer = settingsSlice.reducer;

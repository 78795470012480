import React, { useEffect, useState } from 'react';
import { Launch } from '@mui/icons-material';
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { ALLOWED_COOPERATION_STATUSES } from '~/constants';
import { environment } from '~/environments';
import { usePagination } from '~/hooks';
import { ApiListResponse, Supplier, SupplierApi } from '~/types';
import { api, suppliersParser } from '~/utils';

export const Suppliers = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [total, setTotal] = useState(0);
  const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
    usePagination();

  const fetchSuppliers = async () => {
    try {
      const response = await api.get<ApiListResponse<SupplierApi>>(
        `/suppliers?limit=${rowsPerPage}&offset=${
          page * rowsPerPage
        }&cooperation_status=${ALLOWED_COOPERATION_STATUSES.join(',')}`,
      );

      setSuppliers(suppliersParser(response.data.data));
      setTotal(response.data.total);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Typography variant="h1">Suppliers</Typography>
        </Grid>

        <Grid item sx={{ height: 'calc(100% - 55px - 34px)', width: '100%' }}>
          <TableContainer sx={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Company Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier) => {
                  return (
                    <TableRow key={supplier.id}>
                      <TableCell>
                        <Link
                          href={
                            environment.adminUrl + '/suppliers/' + supplier.id
                          }
                          target="_blank"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {supplier.id}
                          <Launch fontSize="small" sx={{ marginLeft: 0.5 }} />
                        </Link>
                      </TableCell>
                      <TableCell>{supplier.country}</TableCell>
                      <TableCell>{supplier.city}</TableCell>
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell>{supplier.website}</TableCell>
                      <TableCell>{supplier.email}</TableCell>
                      <TableCell>{supplier.companyCategory}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={total}
            showFirstButton={true}
            showLastButton={true}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 10000]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

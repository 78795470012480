/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { SupplierSelect } from '~/components';
import { ALLOCATION_REQUEST_PRICE_MARGIN } from '~/constants';
import { Booking, Currency, SupplierOption } from '~/types';

type SupplierInput = {
  suppliers?: SupplierOption[];
  hasCustomPrice: boolean;
  price?: number;
  margin?: number;
};

export type SendRequestInput = {
  supplierIds: number[];
  hasCustomPrice: boolean;
  price?: number;
};

type Props = {
  booking: Booking;
  onCancel: () => void;
  onSubmit: (data: SendRequestInput) => void;
};

const CurrencySymbol: Partial<Record<Currency, string>> = {
  [Currency.EUR]: '€',
  [Currency.USD]: '$',
  [Currency.GBP]: '£',
};

export const SendRequestDialog = ({ booking, onCancel, onSubmit }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [margin, setMargin] = useState(ALLOCATION_REQUEST_PRICE_MARGIN);
  const [expanded, setExpanded] = useState(false);
  const { control, handleSubmit, setValue } = useForm<SupplierInput>({
    defaultValues: {
      suppliers: [],
      hasCustomPrice: false,
      price: undefined,
      margin: undefined,
    },
  });

  useEffect(() => {
    setValue('margin', margin);
    setValue(
      'price',
      Math.round(booking.grossClientPrice * (1 - margin / 100) * 100) / 100,
    );
  }, [booking]);

  const sendRequest = async (data: SupplierInput) => {
    if (data.suppliers) {
      setSubmitting(true);
      await onSubmit({
        supplierIds: data.suppliers?.map((supplier) => supplier.value),
        hasCustomPrice: data.hasCustomPrice,
        price: data.price,
      });
      setSubmitting(false);
    }
  };

  const handleHasCustomChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    console.log('handleHasCustomChange', event.target.checked);
    setValue('hasCustomPrice', event.target.checked);
    setExpanded(event.target.checked);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!booking.grossClientPrice) {
      return;
    }

    const newPrice = Number(event.target.value) || 0;
    const newMargin =
      Math.round(
        ((booking.grossClientPrice - newPrice) / booking.grossClientPrice) *
          100 *
          100,
      ) / 100;
    setValue('price', event.target.value === '' ? undefined : newPrice);
    setValue('margin', newMargin);
    setMargin(newMargin);

    return event.target.value;
  };

  const handleMarginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!booking.grossClientPrice) {
      return;
    }

    const newMargin = Number(event.target.value) || 0;
    const newPrice =
      Math.round(booking.grossClientPrice * (1 - newMargin / 100) * 100) / 100;
    setValue('margin', event.target.value === '' ? undefined : newMargin);
    setValue('price', newPrice);
    setMargin(newMargin);
  };

  return (
    <form onSubmit={handleSubmit(sendRequest)}>
      <DialogTitle variant="h2">Select Supplier</DialogTitle>
      <DialogContent>
        <Grid container direction="column" sx={{ paddingTop: 2 }}>
          <Grid item>
            <Controller
              name="suppliers"
              control={control}
              render={({ field }) => <SupplierSelect {...field} isMulti />}
            />
          </Grid>
          <Grid item>
            <Accordion elevation={0} square expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  padding: 0,
                  paddingLeft: 1,
                  '& .MuiAccordionSummary-content': {
                    alignItems: 'center',
                    marginBottom: '0',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    marginBottom: '0',
                  },
                }}
              >
                <FormControlLabel
                  label="Custom Price"
                  control={
                    <Controller
                      name="hasCustomPrice"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          sx={{ marginLeft: -1 }}
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleHasCustomChange}
                        />
                      )}
                    />
                  }
                />
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">
                      The default margin is {ALLOCATION_REQUEST_PRICE_MARGIN}%.
                      You can specify the price or margin manually. The
                      respective values are calculated accordingly.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={6}>
                        <Controller
                          name="price"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Price"
                              fullWidth
                              size="small"
                              onChange={handlePriceChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {CurrencySymbol[booking.clientCurrency] ||
                                      ''}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="margin"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={margin < ALLOCATION_REQUEST_PRICE_MARGIN}
                              label="Margin"
                              fullWidth
                              size="small"
                              onChange={handleMarginChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={submitting}
        >
          Send
        </Button>
      </DialogActions>
    </form>
  );
};

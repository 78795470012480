import { format } from 'date-fns-tz';
import { DATETIME_FORMAT } from '~/constants';
import { Confirmation, ConfirmationApi } from '~/types';

export const confirmationParser = (
  confirmation: ConfirmationApi,
): Confirmation => {
  return {
    id: confirmation.id,
    bookingId: confirmation.booking_id,
    supplierId: confirmation.supplier_id,
    supplierName: confirmation.supplier_name,
    status: confirmation.status,
    sentAt: confirmation.sent_at,
    sentAtFormatted: format(new Date(confirmation.sent_at), DATETIME_FORMAT),
    confirmedAt: confirmation.confirmed_at,
    confirmedAtFormatted:
      confirmation.confirmed_at &&
      format(new Date(confirmation.confirmed_at), DATETIME_FORMAT),
    declinedAt: confirmation.declined_at,
    declinedAtFormatted:
      confirmation.declined_at &&
      format(new Date(confirmation.declined_at), DATETIME_FORMAT),
    cancelledAt: confirmation.cancelled_at,
    cancelledAtFormatted:
      confirmation.cancelled_at &&
      format(new Date(confirmation.cancelled_at), DATETIME_FORMAT),
    validUntil: confirmation.valid_until,
    validUntilFormatted: format(
      new Date(confirmation.valid_until),
      DATETIME_FORMAT,
    ),
    initiator: confirmation.initiator,
    price: confirmation.price,
  };
};

export const confirmationsParser = (
  confirmations: ConfirmationApi[],
): Confirmation[] =>
  confirmations.map((confirmation) => confirmationParser(confirmation));

import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { useToggle } from '~/hooks';
import { Confirmation } from '~/types';
import { AllocationRequestTable, CancelRequest } from './components';

export const AllocationRequests = () => {
  const [triggerFetchRequests, fetchRequests] = useToggle();
  const [isCancellingRequest, toggleCancellingRequest] =
    useState<Confirmation | null>(null);

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Typography variant="h1">Allocation requests</Typography>
        </Grid>

        <Grid item sx={{ height: 'calc(100% - 55px - 34px)', width: '100%' }}>
          <AllocationRequestTable
            triggerFetchRequests={triggerFetchRequests}
            onCancel={(request) => toggleCancellingRequest(request)}
          />
        </Grid>
      </Grid>

      {isCancellingRequest && (
        <CancelRequest
          request={isCancellingRequest}
          onSuccess={fetchRequests}
          onClose={() => toggleCancellingRequest(null)}
        />
      )}
    </Box>
  );
};

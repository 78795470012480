import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material';
import { DataElement } from '~/components';
import { useToggle } from '~/hooks';
import { Booking, ServiceCategoryLabel } from '~/types';

type Props = {
  booking: Booking;
};

export const BookingDetails = ({ booking }: Props) => {
  const [expanded, toggle] = useToggle(true);

  return (
    <Card>
      <CardHeader
        title="Booking details"
        action={
          <IconButton onClick={toggle}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={expanded}>
        <CardContent>
          <Grid container direction="column">
            <Grid item container>
              <Grid item xs={4}>
                <DataElement
                  label="Service Type"
                  value={booking.serviceClass}
                />
              </Grid>
              <Grid item xs={4}>
                <DataElement
                  label="Service Category"
                  value={
                    booking.serviceCategory
                      ? ServiceCategoryLabel[booking.serviceCategory]
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <DataElement
                  label="Vehicle Class"
                  value={booking.vehicleClass}
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={4}>
                <DataElement
                  label="Passenger Count"
                  value={booking.passengerCount}
                />
              </Grid>
              <Grid item xs={4}>
                <DataElement
                  label="Checked Luggage Count"
                  value={booking.luggageCount}
                />
              </Grid>
              <Grid item xs={4}>
                <DataElement
                  label="Carry-On Luggage Count"
                  value={booking.carryOnLuggageCount}
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={4}>
                <DataElement label="Distance [km]" value={booking.distance} />
              </Grid>
              <Grid item xs={4}>
                <DataElement label="Duration [h]" value={booking.duration} />
              </Grid>
            </Grid>

            <Grid item>
              <DataElement label="Internal Comment" value={booking.comment} />
            </Grid>

            <Grid item>
              <DataElement
                label="Client Additional Requests"
                value={booking.clientAdditionalRequests}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

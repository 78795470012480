import React from 'react';
import { Dialog } from '@mui/material';

import { Booking } from '~/types';
import { api } from '~/utils';

import { SendRequestDialog, SendRequestInput } from './SendRequestDialog';

type Props = {
  onClose: () => void;
  booking: Booking;
};

export const SendRequest = ({ booking, onClose }: Props) => {
  const handleSubmit = async (data: SendRequestInput) => {
    try {
      await Promise.all(
        data.supplierIds.map((supplierId) =>
          api.post(`/bookings/${booking.id}/request`, {
            supplier_id: supplierId,
            ...(data.hasCustomPrice && { price: data.price }),
          }),
        ),
      );
      onClose();
    } catch (e) {
      console.log('Error sending a request');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <SendRequestDialog
        booking={booking}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import { Location } from 'history';
import { useNavigate, useLocation } from 'react-router';
import { VolubusLogoBlue } from '~/assets';

const components = {
  Header() {
    return (
      <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <img
          src={VolubusLogoBlue}
          alt="Volubus Logo"
          style={{ width: '300px' }}
        />
      </Box>
    );
  },
};

type LocationState = {
  from: Location;
};

export const SignIn = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const from = state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Authenticator components={components} hideSignUp={true} />
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { Clear, Edit, Launch } from '@mui/icons-material';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { environment } from '~/environments';
import { Location, LocationSupplier, LocationSupplierApi } from '~/types';
import { api, locationSuppliersParser } from '~/utils';

type Props = {
  location: Location;
  triggerFetchSuppliers: boolean;
  onEdit: (supplier: LocationSupplier) => void;
  onRemove: (supplierId: LocationSupplier['id']) => void;
};

export const LocationSuppliers = ({
  location,
  triggerFetchSuppliers,
  onEdit,
  onRemove,
}: Props) => {
  const [suppliers, setSuppliers] = useState<LocationSupplier[]>([]);

  const fetchSuppliers = async () => {
    try {
      const response = await api.get<LocationSupplierApi[]>(
        `/locations/${location.id}/suppliers`,
      );
      setSuppliers(locationSuppliersParser(response.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [triggerFetchSuppliers]);

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Supplier Id</TableCell>
            <TableCell>Supplier Slug</TableCell>
            <TableCell>Supplier Name</TableCell>
            <TableCell>Supplier Email</TableCell>
            <TableCell width={150}>Priority</TableCell>
            <TableCell>Min Pax #</TableCell>
            <TableCell>Max Pax #</TableCell>
            <TableCell>Quote Prices Only</TableCell>
            <TableCell width={150}>Is Active</TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.map((supplier) => {
            return (
              <TableRow key={supplier.id}>
                <TableCell>
                  <Link
                    href={
                      environment.adminUrl + '/suppliers/' + supplier.supplierId
                    }
                    target="_blank"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {supplier.supplierId}
                    <Launch fontSize="small" sx={{ marginLeft: 0.5 }} />
                  </Link>
                </TableCell>
                <TableCell>{supplier.supplierSlug}</TableCell>
                <TableCell>{supplier.supplierName}</TableCell>
                <TableCell>{supplier.supplierEmail}</TableCell>
                <TableCell>{supplier.priority}</TableCell>
                <TableCell>{supplier.minPaxCount}</TableCell>
                <TableCell>{supplier.maxPaxCount}</TableCell>
                <TableCell>{supplier.quotePricesOnly ? 'Yes' : 'No'}</TableCell>
                <TableCell>{supplier.isActive ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => onEdit(supplier)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => onRemove(supplier.supplierId)}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

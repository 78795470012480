import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { SearchInput } from '~/components';
import {
  ApiListResponse,
  Booking,
  BookingApi,
  TableColumn,
  TableOrder,
} from '~/types';
import { api, bookingsParser } from '~/utils';
import { BookingTable } from './components';

export const Bookings = () => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [total, setTotal] = useState(1000);
  const [order, setOrder] = useState<TableOrder>(TableOrder.DESCENDING);
  const [orderBy, setOrderBy] = useState('booking_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const fetchBookings = async () => {
    setLoading(true);

    try {
      const response = await api.get<ApiListResponse<BookingApi>>(
        `/bookings?sort=${
          orderBy === 'id' ? 'booking_id' : orderBy
        }-${order}&limit=${rowsPerPage}&offset=${
          page * rowsPerPage
        }&search=${search}`,
      );
      setBookings(bookingsParser(response.data.data));
      setTotal(response.data.total);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchBookings();
  }, [search, page, rowsPerPage, orderBy, order]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleFetchBookings = async (
    page: number,
    rowsPerPage: number,
    orderBy: TableColumn<Booking>['sortColumn'],
    order: TableOrder,
  ) => {
    setPage(page);
    setRowsPerPage(rowsPerPage);
    orderBy && setOrderBy(orderBy);
    order && setOrder(order);
  };

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1">Bookings</Typography>
            <SearchInput onRequestSearch={handleSearch} />
          </Box>
        </Grid>

        <Grid item sx={{ height: 'calc(100% - 55px - 34px)', width: '100%' }}>
          <BookingTable
            loading={loading}
            bookings={bookings}
            total={total}
            onFetchBookings={handleFetchBookings}
          ></BookingTable>
        </Grid>
      </Grid>
    </Box>
  );
};

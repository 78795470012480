import { Theme } from '@aws-amplify/ui-react';

export const theme: Theme = {
  name: 'volubus-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: '#fff' },
          80: { value: '#5aaaff' },
          90: { value: '#5aaaff' },
          100: { value: '#5aaaff' },
        },
      },
    },
  },
};

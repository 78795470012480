import { environment as devEnvironment } from './development';
import { environment as localEnvironment } from './local';
import { environment as prodEnvironment } from './production';
import { environment as stageEnvironment } from './stage';
import { Environment } from './type';

const environments: Record<string, Environment> = {
  dev: devEnvironment,
  stage: stageEnvironment,
  prod: prodEnvironment,
};

console.log(`Using ${process.env.REACT_APP_ENV} environment configuration.`);

export const environment =
  environments[process.env.REACT_APP_ENV || ''] || localEnvironment;

import React, { useEffect, useState } from 'react';
import { ArrowBackIos, Launch } from '@mui/icons-material';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Status } from '~/components';
import { environment } from '~/environments';
import { Booking as BookingType, BookingApi } from '~/types';
import { api, bookingParser } from '~/utils';
import {
  AllocationRequests,
  BookingDetails,
  Client,
  Supplier,
} from './components';

export const Booking = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [booking, setBooking] = useState<BookingType>();

  const fetchBooking = async () => {
    try {
      const response = await api.get<BookingApi>(
        `/bookings/${params.bookingId}`,
      );
      setBooking(bookingParser(response.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBooking();
  }, []);

  const handleBackClick = () => {
    navigate('/bookings');
  };

  if (!booking) {
    return null;
  }

  return (
    <Grid container sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Button
          color="secondary"
          startIcon={<ArrowBackIos />}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography
              variant="h1"
              sx={{ lineHeight: '36px', height: '36px' }}
            >
              {booking.bookingId}
            </Typography>
          </Grid>
          <Grid item>
            <Status status={booking.status} />
          </Grid>
          <Grid item>
            <Link
              href={environment.adminUrl + '/bookings/' + booking.id}
              target="_blank"
              sx={{ marginLeft: 1, display: 'flex', alignItems: 'center' }}
            >
              <Launch />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Pickup:</Typography>
            <Typography variant="body1">{booking.pickupLocation}</Typography>
            <Typography variant="body1">
              {booking.pickupLocalDateFormatted}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Destination:</Typography>
            <Typography variant="body1">{booking.destination}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BookingDetails booking={booking} />
      </Grid>
      <Grid item xs={12}>
        <Client client={booking.client} />
      </Grid>
      <Grid item xs={12}>
        <Supplier supplier={booking.supplier} />
      </Grid>
      <Grid item xs={12}>
        <AllocationRequests booking={booking} />
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '~/components';
import type { AppDispatch, RootState } from '~/store';
import { Settings } from '~/types';

type Props = {
  automaticAllocation: Settings['automaticAllocation'];
  onClose: () => void;
};

export const ConfirmAutomaticAllocationDialog = ({
  automaticAllocation,
  onClose,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.settings);

  const handleUpdateSettings = async () => {
    try {
      await dispatch(updateSettings({ automaticAllocation }));
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <DialogTitle variant="h2">Really?</DialogTitle>

      <DialogContent>
        {automaticAllocation ? (
          <Typography>
            Are you sure you want to enable automatic allocation?
            <br />
            Suppliers may start receiving emails with new confirmation requests.
          </Typography>
        ) : (
          <Typography>
            Are you sure you want to disable automatic allocation?
            <br />
            Suppliers will stop receiving new confirmation requests and bookings
            may remain unallocated.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleUpdateSettings}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

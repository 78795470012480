import React from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { LocationSupplier, LocationSupplierInput } from '~/types';

type Props = {
  supplier: LocationSupplier;
  onCancel: () => void;
  onSubmit: (data: LocationSupplierInput) => void;
};

export const EditSupplierDialog = ({ supplier, onCancel, onSubmit }: Props) => {
  const { control, formState, handleSubmit } = useForm<LocationSupplierInput>({
    defaultValues: {
      supplier: { value: supplier.supplierId, label: supplier.supplierName },
      priority: supplier.priority,
      minPaxCount: supplier.minPaxCount ?? null,
      maxPaxCount: supplier.maxPaxCount ?? null,
      isActive: supplier.isActive,
      quotePricesOnly: supplier.quotePricesOnly,
    },
  });

  const addSupplier = async (data: LocationSupplierInput) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(addSupplier)}>
      <DialogTitle variant="h2">Add Supplier</DialogTitle>
      <DialogContent>
        <Grid container sx={{ paddingTop: 2 }}>
          <Grid item xs={6}>
            <TextField
              value={supplier.supplierName}
              label="Supplier"
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Priority"
                  type="number"
                  error={!!formState?.errors?.priority}
                  helperText={formState?.errors?.priority?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="minPaxCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Min Pax Count"
                  type="number"
                  error={!!formState?.errors?.minPaxCount}
                  helperText={formState?.errors?.minPaxCount?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="maxPaxCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Max Pax Count"
                  type="number"
                  error={!!formState?.errors?.maxPaxCount}
                  helperText={formState?.errors?.maxPaxCount?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <FormControlLabel
                  label="Active"
                  control={
                    <Checkbox checked={value} onChange={onChange} {...field} />
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="quotePricesOnly"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <FormControlLabel
                  label="Quote Prices Only"
                  control={
                    <Checkbox checked={value} onChange={onChange} {...field} />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

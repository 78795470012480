import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { Layout } from './components';
import {
  AllocationRequests,
  Booking,
  Bookings,
  Clients,
  ConfirmBooking,
  DeclineBooking,
  Location,
  Locations,
  Settings,
  SignIn,
  Strategies,
  Suppliers,
} from './modules';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route path="confirm" element={<ConfirmBooking />} />
      <Route path="decline" element={<DeclineBooking />} />
      <Route
        path="*"
        element={
          <Layout>
            <Routes>
              <Route path="settings" element={<Settings />}></Route>
              <Route path="bookings">
                <Route path="" element={<Bookings />}></Route>
                <Route path=":bookingId" element={<Booking />}></Route>
              </Route>
              <Route
                path="allocation-requests"
                element={<AllocationRequests />}
              ></Route>
              <Route path="locations">
                <Route path="" element={<Locations />}></Route>
                <Route path=":locationId" element={<Location />}></Route>
              </Route>
              <Route path="clients" element={<Clients />}></Route>
              <Route path="suppliers" element={<Suppliers />}></Route>
              <Route path="strategies" element={<Strategies />}></Route>
              <Route
                path="*"
                element={<Navigate to="/bookings" replace />}
              ></Route>
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
};

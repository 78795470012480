import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.up('xs'));
  const small = useMediaQuery(theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const large = useMediaQuery(theme.breakpoints.up('lg'));
  const extraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    extraSmall,
    xs: extraSmall,
    small,
    sm: small,
    medium,
    md: medium,
    large,
    lg: large,
    extraLarge,
    xl: extraLarge,
  };
};

import React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  label?: string;
  value?: string | number;
};

export const DataElement = ({ label, value }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      <Typography variant="body1">{value ?? '-'}</Typography>
    </Box>
  );
};

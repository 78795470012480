import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '~/components';
import type { AppDispatch, RootState } from '~/store';
import { Settings } from '~/types';

type Props = {
  defaultStrategy: Settings['defaultStrategy'];
  onClose: () => void;
};

export const ConfirmDefaultStategyDialog = ({
  defaultStrategy,
  onClose,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.settings);

  const handleUpdateSettings = async () => {
    try {
      await dispatch(updateSettings({ defaultStrategy }));
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <DialogTitle variant="h2">Really?</DialogTitle>

      <DialogContent>
        <Typography>
          Are you sure you want to update the default strategy?
          <br />
          It will apply to every location without an override strategy.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleUpdateSettings}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { environment as devEnvironment } from './development';
import { Environment } from './type';

export const environment: Environment = {
  awsRegion: process.env.REACT_APP_AWS_REGION || devEnvironment.awsRegion,
  awsUserPoolId:
    process.env.REACT_APP_AWS_USER_POOL_ID || devEnvironment.awsUserPoolId,
  awsUserPoolWebClientId:
    process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ||
    devEnvironment.awsUserPoolWebClientId,
  adminUrl: process.env.REACT_APP_ADMIN_URL || devEnvironment.adminUrl,
  apiUrl:
    process.env.REACT_APP_API_URL ||
    'https://dev.allocation.volubus.com/api/v1',
};

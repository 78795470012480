import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Strategy, StrategyInput } from '~/types';

type Props = {
  strategy: Strategy;
  onCancel: () => void;
  onSubmit: (data: StrategyInput) => void;
};

export const EditStrategyDialog = ({ strategy, onCancel, onSubmit }: Props) => {
  const { control, formState, handleSubmit } = useForm<StrategyInput>({
    defaultValues: {
      id: strategy.id,
      name: strategy.name ?? '',
      description: strategy.description ?? '',
      config: JSON.stringify(strategy.config) ?? '',
    },
  });

  const editStrategy = async (data: StrategyInput) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(editStrategy)}>
      <DialogTitle variant="h2">Edit strategy</DialogTitle>
      <DialogContent>
        <Grid container sx={{ paddingTop: 2 }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  error={!!formState?.errors?.name}
                  helperText={formState?.errors?.name?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  error={!!formState?.errors?.description}
                  helperText={formState?.errors?.description?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="config"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Config"
                  error={true}
                  helperText="Edit this field with high caution!"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

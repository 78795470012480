import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { Confirmation } from '~/types';
import { api } from '~/utils';

type Props = {
  request: Confirmation;
  onSuccess: () => void;
  onClose: () => void;
};

export const CancelRequest = ({ request, onSuccess, onClose }: Props) => {
  const handleCancel = async () => {
    try {
      await api.post(`/requests/${request.id}/cancel`, {
        cancelled_at: new Date().toUTCString(),
        status_id: 5,
      });
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error cancelling request');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="xs">
      <DialogTitle variant="h2">Really?</DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" color="error" onClick={handleCancel}>
          Cancel request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

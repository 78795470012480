import React, { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { MenuListProps } from '@mui/material';
import Select, {
  SelectInstance,
  Props as ReactSelectProps,
  GroupBase,
  InputActionMeta,
} from 'react-select';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { ALLOWED_COOPERATION_STATUSES } from '~/constants';
import {
  ApiListResponse,
  Supplier,
  SupplierApi,
  SupplierOption,
} from '~/types';
import { api, suppliersParser } from '~/utils';

// let scrollPosition = 0;

type Props = ReactSelectProps<
  SupplierOption,
  boolean,
  GroupBase<SupplierOption>
> & {
  error?: boolean;
};

export const SupplierSelect = forwardRef(
  (
    props: Props,
    ref: Ref<
      SelectInstance<SupplierOption, boolean, GroupBase<SupplierOption>>
    >,
  ) => {
    // const listRef = useRef<FixedSizeList>(null);
    const infiniteLoaderRef = useRef<InfiniteLoader>(null);
    const hasMountedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [total, setTotal] = useState(1);
    const [search, setSearch] = useState('');

    const options: SupplierOption[] = suppliers.map(({ id, name }) => {
      return { value: id, label: name };
    });

    const fetchSuppliers = async (startIndex = 0) => {
      if (loading) {
        return;
      }
      // const peristScroll = scrollPosition;

      setLoading(true);
      // setTimeout(() => {
      //   listRef?.current?.scrollTo(peristScroll);
      // }, 0);
      try {
        const response = await api.get<ApiListResponse<SupplierApi>>(
          `/suppliers?search=${search}&limit=${50}&offset=${startIndex}&cooperation_status=${ALLOWED_COOPERATION_STATUSES.join(
            ',',
          )}
          `,
        );

        const newSuppliers = suppliersParser(response.data.data);
        const updatedSuppliers = [...suppliers];
        updatedSuppliers.splice(
          startIndex,
          newSuppliers.length,
          ...newSuppliers,
        );
        setSuppliers(updatedSuppliers);

        setLoading(false);
        // setTimeout(() => {
        //   listRef?.current?.scrollTo(peristScroll);
        // }, 0);
        setTotal(response.data.total);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    useEffect(() => {
      const getData = setTimeout(() => {
        if (hasMountedRef.current) {
          if (infiniteLoaderRef.current) {
            infiniteLoaderRef.current.resetloadMoreItemsCache();
          }
        }
        hasMountedRef.current = true;

        fetchSuppliers();
      }, 500);

      return () => clearTimeout(getData);
    }, [search]);

    const isItemLoaded = (index: number) => {
      return !!suppliers[index];
    };

    // const handleScroll = ({ scrollOffset }: { scrollOffset: number }) => {
    //   scrollPosition = scrollOffset;
    // };

    const MenuList = (props: MenuListProps) => {
      const children = Array.isArray(props.children)
        ? props.children
        : [props.children];

      const Item = ({
        index,
        style,
      }: ListChildComponentProps<SupplierOption>) => (
        <div style={style}>
          <span>{children[index] ? children[index] : 'Loading...'}</span>
        </div>
      );

      return (
        <AutoSizer disableHeight>
          {({ width }) => (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={total}
              loadMoreItems={fetchSuppliers}
              minimumBatchSize={50}
              threshold={30}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  itemCount={total}
                  itemSize={49}
                  itemKey={(index) => children[index]?.key}
                  height={300}
                  width={width}
                  // onScroll={handleScroll}
                  overscanCount={10}
                >
                  {Item}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      );
    };

    const handleInputChange = (newValue: string, reason: InputActionMeta) => {
      if (
        reason.action === 'set-value' ||
        reason.action === 'input-blur' ||
        reason.action === 'menu-close'
      ) {
        return;
      }

      const inputValue = newValue;
      setSearch(inputValue);
      return inputValue;
    };

    return (
      <Select
        {...props}
        ref={ref}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        isLoading={loading}
        options={options}
        components={{ MenuList }}
        onInputChange={handleInputChange}
        filterOption={() => true}
      />
    );
  },
);
SupplierSelect.displayName = 'SupplierSelect';

import { Client, ClientApi } from '~/types';

export const clientParser = (client: ClientApi): Client => {
  return {
    id: client.id,
    clientId: client.client_id,
    name: client.name,
    ...(client.code && { code: client.code }),
    ...(client.client_type && { clientType: client.client_type }),
    ...(client.person && { person: client.person }),
    ...(client.position && { position: client.position }),
    ...(client.email && { email: client.email }),
    ...(client.telephone && { telephone: client.telephone }),
    ...(client.billing_address && { billingAddress: client.billing_address }),
    ...(client.city && { city: client.city }),
    ...(client.zip_code && { zipCode: client.zip_code }),
    country: client.country,
    ...(client.vat_id && { vatId: client.vat_id }),
    preferredPaymentMethod: client.preferred_payment_method,
    vatExempted: client.vat_excempted,
    margin: client.margin,
    clientCommission: client.client_commission,
    author: client.author,
    isArchived: client.is_archived,
  };
};

export const clientsParser = (clients: ClientApi[]): Client[] =>
  clients.map((client) => clientParser(client));

import { Country } from './Country';

export enum ClientType {
  INDIVIDUAL = 'INDIVIDUAL',
  TRAVEL_AGENT = 'TRAVEL_AGENT',
  TRAVEL_MANAGEMENT_COMPANY = 'TRAVEL_MANAGEMENT_COMPANY',
  AIRLINE = 'AIRLINE',
  ONLINE_PLATFORM_MANUAL = 'ONLINE_PLATFORM_MANUAL',
}

export enum PreferredPaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  MONTHLY_INVOICE = 'MONTHLY_INVOICE',
  PAYPAL = 'PAYPAL',
}

export type ClientBaseApi = {
  id: number;
  client_id: string;
  name: string;
};

export type ClientApi = ClientBaseApi & {
  code: string | null;
  client_type: ClientType | null;
  person: string | null;
  position: string | null;
  email: string | null;
  telephone: string | null;
  billing_address: string | null;
  city: string | null;
  zip_code: string | null;
  country: Country;
  vat_id: string | null;
  preferred_payment_method: PreferredPaymentMethod;
  vat_excempted: boolean;
  margin: number;
  client_commission: number;
  author: string;
  is_archived: boolean;
};

export type ClientBase = {
  id: number;
  clientId: string;
  name: string;
};

export type Client = ClientBase & {
  code?: string;
  clientType?: ClientType;
  person?: string;
  position?: string;
  email?: string;
  telephone?: string;
  billingAddress?: string;
  city?: string;
  zipCode?: string;
  country: Country;
  vatId?: string;
  preferredPaymentMethod: PreferredPaymentMethod;
  vatExempted: boolean;
  margin: number;
  clientCommission: number;
  author: string;
  isArchived: boolean;
};

import React, { createContext } from 'react';
import { useToggle } from '~/hooks';
import { ReactProps } from '~/types';

export type LayoutContextType = {
  isSidebarOpen: boolean;
  toggleSidebar: (on?: boolean) => void;
};

export const LayoutContext = createContext<LayoutContextType>(
  {} as LayoutContextType,
);
LayoutContext.displayName = 'LayoutContext';

export const LayoutProvider = ({ children }: ReactProps) => {
  const [isSidebarOpen, toggleSidebar] = useToggle(false);

  return (
    <LayoutContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
};

import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import type { RootState } from '~/store';
import {
  Settings as SettingsType,
  StrategyLabels,
  StrategyType,
} from '~/types';
import {
  ConfirmAutomaticAllocationDialog,
  ConfirmDefaultStategyDialog,
} from './components';

const { [StrategyType.NONE]: _NONE, ...strategies } = StrategyLabels;
const strategyOptions = Object.entries(strategies).map(([id, label]) => ({
  id,
  label,
}));

export const Settings = () => {
  const settings = useSelector((state: RootState) => state.settings.settings);
  const [confirmAutomaticAllocation, setConfirmAutomaticAllocation] = useState<
    boolean | null
  >(null);
  const [confirmDefaultStrategy, setConfirmDefaultStrategy] = useState<
    number | null
  >(null);

  const handleAutomaticAllocationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmAutomaticAllocation(event.target.checked ? true : false);
  };

  const handleDefaultStrategyChange = async (
    event: SelectChangeEvent<SettingsType['defaultStrategy']>,
  ) => {
    setConfirmDefaultStrategy(Number(event.target.value));
  };

  return (
    <Grid container direction="column" sx={{ padding: 3 }}>
      <Grid item>
        <Typography variant="h1">Settings</Typography>
      </Grid>
      <Grid item>
        {settings && (
          <Grid container direction="column" maxWidth="sm">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.automaticAllocation}
                    onChange={handleAutomaticAllocationChange}
                  />
                }
                label="Automatic allocation"
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Default Strategy</InputLabel>
                <Select
                  label="Default Strategy"
                  value={settings.defaultStrategy}
                  onChange={handleDefaultStrategyChange}
                >
                  {strategyOptions.map((strategy) => (
                    <MenuItem key={strategy.id} value={strategy.id}>
                      {strategy.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>

      {confirmAutomaticAllocation != null && (
        <ConfirmAutomaticAllocationDialog
          automaticAllocation={confirmAutomaticAllocation}
          onClose={() => setConfirmAutomaticAllocation(null)}
        />
      )}

      {confirmDefaultStrategy != null && (
        <ConfirmDefaultStategyDialog
          defaultStrategy={confirmDefaultStrategy}
          onClose={() => setConfirmDefaultStrategy(null)}
        />
      )}
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';
import { ArrowBackIos, Launch } from '@mui/icons-material';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from '~/hooks';
import {
  Location as LocationType,
  LocationApi,
  LocationSupplier,
} from '~/types';
import { api, getCoordinatesLink, locationParser } from '~/utils';
import { AddSupplier, EditSupplier, RemoveSupplier } from './components';
import { LocationSuppliers } from './LocationSuppliers';

export const Location = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [location, setLocation] = useState<LocationType>();
  const [triggerFetchSuppliers, fetchSuppliers] = useToggle();
  const [isAddingSupplier, toggleAddingSupplier] = useToggle();
  const [isEditingSupplier, toggleEditingSupplier] =
    useState<LocationSupplier | null>(null);
  const [isRemovingSupplier, toggleRemovingSupplier] = useState<
    LocationSupplier['supplierId'] | null
  >(null);

  const fetchLocation = async () => {
    try {
      const response = await api.get<LocationApi>(
        `/locations/${params.locationId}`,
      );
      setLocation(locationParser(response.data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  const handleBackClick = () => {
    navigate('/locations');
  };

  if (!location) {
    return null;
  }

  return (
    <>
      <Grid container sx={{ padding: 3 }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                color="secondary"
                startIcon={<ArrowBackIos />}
                onClick={handleBackClick}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleAddingSupplier(true)}
              >
                Add supplier
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Typography variant="h1" sx={{ lineHeight: '36px', height: '36px' }}>
            {location.city}
            {location.country && `, ${location.country}`}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h3">Coordinates:</Typography>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {location.coordinates.x}, {location.coordinates.y}
                <Link
                  href={getCoordinatesLink(location.coordinates)}
                  target="_blank"
                  sx={{ marginLeft: 1, lineHeight: '1em' }}
                >
                  <Launch fontSize="small" />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3">Radius:</Typography>
              <Typography variant="body1">{location.radius}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <LocationSuppliers
            location={location}
            triggerFetchSuppliers={triggerFetchSuppliers}
            onEdit={(locationSupplier) =>
              toggleEditingSupplier(locationSupplier)
            }
            onRemove={(locationSupplierId) =>
              toggleRemovingSupplier(locationSupplierId)
            }
          />
        </Grid>
      </Grid>

      {isAddingSupplier && (
        <AddSupplier
          locationId={location.id}
          onSuccess={fetchSuppliers}
          onClose={() => toggleAddingSupplier(false)}
        />
      )}

      {isEditingSupplier && (
        <EditSupplier
          locationId={location.id}
          supplier={isEditingSupplier}
          onSuccess={fetchSuppliers}
          onClose={() => toggleEditingSupplier(null)}
        />
      )}

      {isRemovingSupplier && (
        <RemoveSupplier
          locationId={location.id}
          supplierId={isRemovingSupplier}
          onSuccess={fetchSuppliers}
          onClose={() => toggleRemovingSupplier(null)}
        />
      )}
    </>
  );
};

import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { LayoutProvider } from './components/Layout/LayoutProvider';
import { store } from './store';
import { ThemeProvider } from './utils';

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <Authenticator.Provider>
            <LayoutProvider>
              <AppRoutes />
            </LayoutProvider>
          </Authenticator.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export enum ServiceCategory {
  STANDARD = 'STANDARD',
  EXECUTIVE = 'EXECUTIVE',
  LUXURY = 'LUXURY',
}

export const ServiceCategoryLabel: Record<ServiceCategory, string> = {
  [ServiceCategory.STANDARD]: 'Standard',
  [ServiceCategory.EXECUTIVE]: 'Business',
  [ServiceCategory.LUXURY]: 'Luxury',
};

import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material';
import { DataElement } from '~/components';
import { useToggle } from '~/hooks';
import { Client as ClientType } from '~/types';

type Props = {
  client: ClientType;
};

export const Client = ({ client }: Props) => {
  const [expanded, toggle] = useToggle(true);

  return (
    <Card>
      <CardHeader
        title="Client"
        action={
          <IconButton onClick={toggle}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={expanded}>
        <CardContent>
          <Grid container direction="column">
            <Grid item container>
              <Grid item>
                <DataElement label="Name" value={client.name} />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={4}>
                <DataElement label="Contact Name" value={client.person} />
              </Grid>
              <Grid item xs={4}>
                <DataElement
                  label="Contact Telephone"
                  value={client.telephone}
                />
              </Grid>
              <Grid item xs={4}>
                <DataElement label="Contact Email" value={client.email} />
              </Grid>
            </Grid>

            <Grid item>
              <DataElement label="Client VAT Number" value={client.vatId} />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DataElement } from '~/components';
import { useToggle } from '~/hooks';
import { Supplier as SupplierType } from '~/types';

type Props = {
  supplier?: SupplierType;
};

export const Supplier = ({ supplier }: Props) => {
  const [expanded, toggle] = useToggle(true);

  return (
    <Card>
      <CardHeader
        title="Supplier"
        action={
          <IconButton onClick={toggle}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={expanded}>
        {supplier ? (
          <CardContent>
            <Grid container direction="column">
              <Grid item container>
                <Grid item>
                  <DataElement label="Name" value={supplier.name} />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={4}>
                  <DataElement label="Contact Name" value={supplier.person} />
                </Grid>
                <Grid item xs={4}>
                  <DataElement
                    label="Contact Telephone"
                    value={supplier.telephone}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DataElement label="Contact Email" value={supplier.email} />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <CardContent>
            <Typography variant="body1">
              There is no supplier data available
            </Typography>
          </CardContent>
        )}
      </Collapse>
    </Card>
  );
};

import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { useToggle } from '~/hooks';
import { Strategy } from '~/types';
import { EditStrategy, StrategyTable } from './components';

export const Strategies = () => {
  const [triggerFetchStrategies, fetchStrategies] = useToggle();
  const [isEditingStrategy, toggleEditingStrategy] = useState<Strategy | null>(
    null,
  );

  return (
    <Box sx={{ padding: 2, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Typography variant="h1">Strategies</Typography>
        </Grid>

        <Grid item sx={{ height: 'calc(100% - 55px - 34px)', width: '100%' }}>
          <StrategyTable
            triggerFetchStrategies={triggerFetchStrategies}
            onEdit={(strategy) => toggleEditingStrategy(strategy)}
          />
        </Grid>
      </Grid>

      {isEditingStrategy && (
        <EditStrategy
          strategy={isEditingStrategy}
          onSuccess={fetchStrategies}
          onClose={() => toggleEditingStrategy(null)}
        />
      )}
    </Box>
  );
};

import React from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import {
  Bus,
  Coach,
  VolubusLogoStripesWhite,
  VolubusLogoWhite,
} from '~/assets';
import { useBreakpoints } from '~/hooks';

type Props = {
  loading: boolean;
  title: React.ReactNode;
  children?: React.ReactNode;
};

export const UserInfo = ({ loading, title, children }: Props) => {
  const { sm, md, lg } = useBreakpoints();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        overflowY: 'hidden',
        minHeight: '100vh',
        padding: 2,
        backgroundColor: 'primary.main',
        fontFamily: 'Messina Sans',
        zIndex: 0,
      }}
    >
      {sm && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: -1,
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: { sm: 820, xl: 720 },
              right: { sm: '-10%', md: '15%', lg: '20%' },
              height: '100%',
              width: '150%',
              border: '20px solid #ffffff',
            }}
          />

          {md && (
            <Box
              component="div"
              sx={{
                position: 'absolute',
                top: 460,
                left: { md: '55%', xl: '60%' },
                height: 540,
                width: '100%',
                border: '20px solid #000000',
                zIndex: -1,
              }}
            />
          )}

          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: 0,
              right: { sm: '20%', md: '27%', lg: '31%' },
              height: '100%',
              width: 20,
              background: '#00e6f6',
              zIndex: -1,
            }}
          />

          {md && (
            <Box
              component="img"
              src={Bus}
              alt="Bus"
              sx={{
                position: 'absolute',
                top: 293,
                left: {
                  md: 'calc(55% + 20px)',
                  xl: 'calc(60% + 20px)',
                },
                width: 392,
              }}
            />
          )}

          <Box
            component="div"
            sx={{
              position: 'absolute',
              top: { sm: 651, xl: 551 },
              right: {
                sm: -50,
                md: 'calc(15% + 91px)',
                lg: 'calc(20% + 91px)',
              },
            }}
          >
            <Box component="img" src={Coach} alt="Bus" sx={{ width: 594 }} />
            {md && (
              <Box
                component="img"
                src={VolubusLogoStripesWhite}
                alt="Bus"
                sx={{ position: 'absolute', top: 70, right: -90, width: 85 }}
              />
            )}
          </Box>
        </Box>
      )}

      <Container maxWidth="xl" fixed>
        <Grid container spacing={0} direction="column">
          <Grid
            item
            sx={{
              paddingTop: { xs: 3, sm: 4, md: 5, lg: 7 },
              paddingBottom: { xs: 3, sm: 4, md: 5, lg: 7 },
            }}
          >
            <Box
              component="img"
              src={VolubusLogoWhite}
              alt="Volubus Logo"
              sx={{ width: { xs: 120, md: 150, lg: 200 } }}
            />
          </Grid>

          {loading && (
            <Grid item>
              <CircularProgress
                size={lg ? 200 : md ? 150 : sm ? 120 : 100}
                thickness={lg ? 6 : md ? 5 : sm ? 4 : 3}
                sx={{ color: 'white' }}
              />{' '}
            </Grid>
          )}

          {!loading && (
            <>
              <Grid item>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: 36, sm: 44, md: 60, lg: 72 },
                    lineHeight: 1.3,
                    marginBottom: 4,
                    fontFamily: 'Messina Sans',
                    maxWidth: { xs: 450, sm: 400, md: 600, lg: 700 },
                  }}
                >
                  {title}
                </Typography>
              </Grid>

              {children}
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

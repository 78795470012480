import React from 'react';
import { Dialog } from '@mui/material';

import { Location, LocationInput, StrategyType } from '~/types';
import { api } from '~/utils';

import { EditLocationDialog } from './EditLocationDialog';

type Props = {
  location: Location;
  onSuccess: () => void;
  onClose: () => void;
};

export const EditLocation = ({ location, onSuccess, onClose }: Props) => {
  const handleSubmit = async ({
    city,
    strategy,
    strategyConfig,
    isActive,
    ...data
  }: LocationInput) => {
    try {
      await api.patch(`/locations/${location.id}`, {
        ...data,
        name: city,
        strategy,
        strategy_config:
          strategy !== StrategyType.NONE && strategyConfig
            ? strategyConfig
            : null,
        is_active: isActive,
      });
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error editing location');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <EditLocationDialog
        location={location}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

import React, { useEffect, useState } from 'react';
import { Clear, Edit, Info, Launch } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Loading } from '~/components';
import { usePagination } from '~/hooks';
import {
  ApiListResponse,
  Location,
  LocationApi,
  StrategyLabels,
  StrategyType,
  TableColumn,
  TableOrder,
} from '~/types';
import { api, getCoordinatesLink, locationsParser } from '~/utils';

import { getLocationTableColumns } from './getLocationTableColumns';

type Props = {
  triggerFetchLocations: boolean;
  onEdit: (location: Location) => void;
  onRemove: (locationId: Location['id']) => void;
};

export const LocationTable = ({
  triggerFetchLocations,
  onEdit,
  onRemove,
}: Props) => {
  const columns = getLocationTableColumns();

  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<TableOrder>(TableOrder.DESCENDING);
  const [orderBy, setOrderBy] = useState('id');
  const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
    usePagination();

  useEffect(() => {
    const controller = new AbortController();

    const fetchLocations = async () => {
      setLoading(true);

      try {
        const sortBy =
          orderBy === 'city'
            ? 'name'
            : orderBy === 'isActive'
            ? 'is_active'
            : orderBy;
        const response = await api.get<ApiListResponse<LocationApi>>(
          `/locations?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
          }&sort=${sortBy}-${order}`,
          {
            signal: controller.signal,
          },
        );
        setLocations(locationsParser(response.data?.data || []));
        setTotal(response.data?.total || 1);
      } catch (e) {
        if (!controller.signal.aborted) {
          console.error(e);
        }
      }

      if (!controller.signal.aborted) {
        setLoading(false);
      }
    };

    fetchLocations();

    return () => {
      controller.abort();
    };
  }, [triggerFetchLocations, page, rowsPerPage, order, orderBy]);

  const renderCell = (
    location: Location,
    cell: TableColumn<Location>['name'],
  ) => {
    switch (cell) {
      case 'city':
        return (
          <TableCell key={cell}>
            <Link component={RouterLink} to={'/locations/' + location.id}>
              {location.city}
            </Link>
          </TableCell>
        );
      case 'coordinates':
        return (
          <TableCell key={cell}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignContent: 'center',
              }}
            >
              {location.coordinates.x}, {location.coordinates.y} (
              <Link
                href={getCoordinatesLink(location.coordinates)}
                target="_blank"
              >
                <Launch fontSize="small" />
              </Link>
              )
            </Box>
          </TableCell>
        );
      case 'suppliers':
        return <TableCell key={cell}>{location.suppliers.length}</TableCell>;
      case 'strategy':
        return (
          <TableCell key={cell}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignContent: 'center',
              }}
            >
              {StrategyLabels[location.strategy]}
              {location.strategy !== StrategyType.NONE &&
                location.strategyConfig &&
                Object.keys(location.strategyConfig).length > 0 && (
                  <Tooltip title={JSON.stringify(location.strategyConfig)}>
                    <Info
                      color="info"
                      fontSize="small"
                      sx={{ marginLeft: 1 }}
                    />
                  </Tooltip>
                )}
            </Box>
          </TableCell>
        );
      case 'isActive':
        return (
          <TableCell key={cell}>{location.isActive ? 'Yes' : 'No'}</TableCell>
        );
      case 'actions':
        return (
          <TableCell key={cell}>
            <IconButton color="secondary" onClick={() => onEdit(location)}>
              <Edit />
            </IconButton>
            <IconButton color="error" onClick={() => onRemove(location.id)}>
              <Clear />
            </IconButton>
          </TableCell>
        );
      default:
        return (
          <TableCell key={cell}>
            {location[cell as keyof Location] as string}
          </TableCell>
        );
    }
  };

  const handleSort = (column: TableColumn<Location>) => {
    const isAsc =
      orderBy === column.sortColumn && order === TableOrder.ASCENDING;
    setOrder(isAsc ? TableOrder.DESCENDING : TableOrder.ASCENDING);
    column.sortColumn && setOrderBy(column.sortColumn);
    handleChangePage(null, 0);
  };

  return (
    <>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortColumn ? (
                  <TableCell
                    key={column.name}
                    sx={{
                      minWidth: column.minWidth ?? 0,
                      whiteSpace: 'nowrap',
                    }}
                    sortDirection={
                      orderBy === column.sortColumn ? order : false
                    }
                    size="small"
                  >
                    <TableSortLabel
                      active={orderBy === column.sortColumn}
                      direction={
                        orderBy === column.sortColumn
                          ? order
                          : TableOrder.ASCENDING
                      }
                      onClick={() => handleSort(column)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.name}
                    sx={{ minWidth: column.minWidth ?? 0 }}
                    size="small"
                  >
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: '100%' }}>
            {loading ? (
              <TableRow sx={{ height: '100%' }}>
                <TableCell colSpan={columns.length}>
                  <Loading message="Loading locations..." />
                </TableCell>
              </TableRow>
            ) : (
              locations.map((location) => (
                <TableRow key={location.id}>
                  {columns.map((column) => renderCell(location, column.name))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={total}
        showFirstButton={true}
        showLastButton={true}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Launch } from '@mui/icons-material';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Loading, Status } from '~/components';
import { environment } from '~/environments';
import { usePagination } from '~/hooks';
import { Booking, TableColumn, TableOrder } from '~/types';

import { getBookingTableColumns } from './getBookingTableColumns';

type Props = {
  loading: boolean;
  bookings: Booking[];
  total: number;
  onFetchBookings: (
    page: number,
    rowsPerPage: number,
    orderBy: TableColumn<Booking>['sortColumn'],
    order: TableOrder,
  ) => void;
};

export const BookingTable = ({
  loading,
  bookings,
  total,
  onFetchBookings,
}: Props) => {
  const columns = getBookingTableColumns();
  const [order, setOrder] = useState<TableOrder>(TableOrder.DESCENDING);
  const [orderBy, setOrderBy] = useState('id');
  const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
    usePagination();

  useEffect(() => {
    onFetchBookings(page, rowsPerPage, orderBy, order);
  }, [page, rowsPerPage, order, orderBy]);

  const renderCell = (booking: Booking, cell: TableColumn<Booking>['name']) => {
    switch (cell) {
      case 'id':
        return (
          <TableCell key={cell}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignContent: 'center',
              }}
            >
              <Link
                component={RouterLink}
                to={'/bookings/' + booking.id}
                sx={{ marginRight: 0.5 }}
              >
                {booking.bookingId}
              </Link>
              (
              <Link
                href={environment.adminUrl + '/bookings/' + booking.id}
                target="_blank"
              >
                <Launch fontSize="small" />
              </Link>
              )
            </Box>
          </TableCell>
        );
      case 'status':
        return (
          <TableCell key={cell}>
            <Status status={booking.status} />
          </TableCell>
        );
      case 'country':
        return (
          <TableCell key={cell}>
            {booking.pickupCountry || '-'}/{booking.destinationCountry || '-'}
          </TableCell>
        );
      case 'date':
        return (
          <TableCell key={cell} sx={{ minWidth: 250 }}>
            <Box component="span" display="block">
              {booking.dateFormatted}
            </Box>
            <Box component="span" display="block">
              {booking.pickupLocalDateFormatted}
            </Box>
          </TableCell>
        );
      case 'clientName':
        return <TableCell key={cell}>{booking.client.name}</TableCell>;
      case 'supplierName':
        return <TableCell key={cell}>{booking.supplier?.name}</TableCell>;
      default:
        return (
          <TableCell key={cell}>
            {booking[cell as keyof Booking] as string}
          </TableCell>
        );
    }
  };

  const handleSort = (column: TableColumn<Booking>) => {
    const isAsc =
      orderBy === column.sortColumn && order === TableOrder.ASCENDING;
    setOrder(isAsc ? TableOrder.DESCENDING : TableOrder.ASCENDING);
    column.sortColumn && setOrderBy(column.sortColumn);
    handleChangePage(null, 0);
  };

  return (
    <>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.sortColumn ? (
                  <TableCell
                    key={column.name}
                    sx={{
                      minWidth: column.minWidth ?? 0,
                      whiteSpace: 'nowrap',
                    }}
                    sortDirection={
                      orderBy === column.sortColumn ? order : false
                    }
                    size="small"
                  >
                    <TableSortLabel
                      active={orderBy === column.sortColumn}
                      direction={orderBy === column.sortColumn ? order : 'asc'}
                      onClick={() => handleSort(column)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.name}
                    sx={{ minWidth: column.minWidth ?? 0 }}
                    size="small"
                  >
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: '100%' }}>
            {loading ? (
              <TableRow sx={{ height: '100%' }}>
                <TableCell colSpan={columns.length}>
                  <Loading message="Loading bookings..." />
                </TableCell>
              </TableRow>
            ) : (
              bookings.map((booking) => (
                <TableRow key={booking.id}>
                  {columns.map((column) => renderCell(booking, column.name))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={total}
        showFirstButton={true}
        showLastButton={true}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 10000]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

import React from 'react';
import { Dialog } from '@mui/material';

import { LocationInput, StrategyType } from '~/types';
import { api } from '~/utils';

import { AddLocationDialog } from './AddLocationDialog';

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

export const AddLocation = ({ onSuccess, onClose }: Props) => {
  const handleSubmit = async ({
    city,
    strategy,
    strategyConfig,
    isActive,
    ...data
  }: LocationInput) => {
    try {
      await api.post('/locations', {
        ...data,
        name: city,
        strategy,
        strategy_config:
          strategy !== StrategyType.NONE && strategyConfig
            ? strategyConfig
            : null,
        is_active: isActive,
      });
      onSuccess();
      onClose();
    } catch (e) {
      console.log('Error adding location');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <AddLocationDialog onCancel={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};

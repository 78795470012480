import { Location, TableColumn } from '~/types';

export const getLocationTableColumns = (): TableColumn<Location>[] => {
  return [
    {
      name: 'id',
      label: 'ID',
      sortColumn: 'id',
    },
    {
      name: 'city',
      label: 'City',
      sortColumn: 'city',
    },
    {
      name: 'country',
      label: 'Country',
      sortColumn: 'country',
    },
    {
      name: 'coordinates',
      label: 'Coordinates',
      minWidth: 250,
    },
    {
      name: 'radius',
      label: 'Radius',
      minWidth: 150,
      sortColumn: 'radius',
    },
    {
      name: 'suppliers',
      label: '# of Suppliers',
      minWidth: 150,
      sortColumn: 'suppliers',
    },
    {
      name: 'strategy',
      label: 'Strategy',
      minWidth: 150,
      sortColumn: 'strategy',
    },
    {
      name: 'isActive',
      label: 'Active',
      sortColumn: 'isActive',
      minWidth: 100,
    },
    {
      name: 'actions',
      label: '',
      minWidth: 150,
    },
  ];
};

import { Strategy, TableColumn } from '~/types';

export const getStrategyTableColumns = (): TableColumn<Strategy>[] => {
  return [
    {
      name: 'id',
      label: 'ID',
      sortColumn: 'id',
    },
    {
      name: 'name',
      label: 'Name',
      sortColumn: 'name',
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'config',
      label: 'Config',
    },
    {
      name: 'actions',
      label: '',
      width: 100,
    },
  ];
};

import React from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LocationInput, StrategyType } from '~/types';
import { strategyOptions } from './constants';

type Props = {
  onCancel: () => void;
  onSubmit: (data: LocationInput) => void;
};

export const AddLocationDialog = ({ onCancel, onSubmit }: Props) => {
  const { control, formState, handleSubmit, watch } = useForm<LocationInput>({
    defaultValues: {
      city: '',
      country: '',
      coordinates: '',
      radius: 100,
      strategy: StrategyType.NONE,
      strategyConfig: '',
      isActive: true,
    },
  });

  const addLocation = async (data: LocationInput) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(addLocation)}>
      <DialogTitle variant="h2">Add Location</DialogTitle>
      <DialogContent>
        <Grid container sx={{ paddingTop: 2 }}>
          <Grid item xs={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City"
                  error={!!formState?.errors?.city}
                  helperText={formState?.errors?.city?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Country"
                  error={!!formState?.errors?.country}
                  helperText={formState?.errors?.country?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="coordinates"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Coordinates"
                  error={!!formState?.errors?.coordinates}
                  helperText={formState?.errors?.coordinates?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="radius"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Radius"
                  error={!!formState?.errors?.radius}
                  helperText={formState?.errors?.radius?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="strategy"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Strategy</InputLabel>
                  <Select {...field} label="Strategy" fullWidth>
                    {strategyOptions.map((strategy) => (
                      <MenuItem key={strategy.id} value={strategy.id}>
                        {strategy.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="strategyConfig"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Config"
                  error={true}
                  helperText="Edit this field with high caution!"
                  disabled={watch('strategy') === StrategyType.NONE}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="isActive"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <FormControlLabel
                  label="Active"
                  control={
                    <Checkbox checked={value} onChange={onChange} {...field} />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Add
        </Button>
      </DialogActions>
    </form>
  );
};
